export const creditsData = [
  {
    id: 1,
    title: 'TECHNICAL DIRECTOR',
    name: 'KRZYSZTOF WYRZYKOWSKI',
  },
  {
    id: 2,
    title: 'TECHNICAL LEADER',
    name: 'TOMASZ KRAJEWSKI',
  },
  {
    id: 3,
    title: 'PROJECT MANAGER',
    name: 'CEZARY LEWCZUK',
  },
  {
    id: 4,
    title: 'DESIGN DIRECTOR',
    name: 'KINGA KACZYŃSKA',
  },
  {
    id: 5,
    title: 'LEAD DESIGNER',
    name: 'WERONIKA OSTROWSKA',
  },
  {
    id: 6,
    title: 'DEVOPS',
    name: 'TOMASZ WOJTUNIK',
  },
  {
    id: 7,
    title: 'DEVELOPMENT TEAM',
    name: 'MICHAŁ CHOLEWIŃSKI',
  },
  {
    id: 8,
    title: '',
    name: 'PIOTR CZUBAK',
  },
  {
    id: 9,
    title: '',
    name: 'PATRYK ORDON',
  },
  {
    id: 10,
    title: 'ADDITIONAL QA SUPPORT',
    name: 'MARCIN SKRZYŃSKI',
  },
  {
    id: 11,
    title: 'ADDITIONAL DESIGN SUPPORT',
    name: 'PIOTR STANISŁAWSKI',
  },
  {
    id: 12,
    title: 'COPYRIGHTS',
    name: '',
  },
  {
    id: 13,
    title: '',
    name: '© CHMIELEWSKI HENRYK JERZY',
  },
  {
    id: 14,
    title: '',
    name: '© PRÓSZYŃSKI MEDIA SP. Z O.O. 1998-2020 WSZELKIE PRAWA ZASTRZEŻONE',
  },
  {
    id: 15,
    title: '',
    name: 'GRA POWSTAŁA Z WYKORZYSTANIEM RYSUNKÓW HENRYKA JERZEGO CHMIELEWSKIEGO WE WSPÓŁPRACY Z CENTRUM KOMIKSU I NARRACJI INTERAKTYWNEJ W ŁODZI.'
  },
  {
    id: 16,
    title: '',
    name:
      "HENRYK JERZY CHMIELEWSKI JEST AUTOREM SERII KOMIKSÓW 'TYTUS, ROMEK I A'TOMEK', KTÓREJ POSTACI ZNAJDUJĄ SIĘ W GRZE.",
  },
  {
    id: 17,
    title: 'DŹWIĘKI/MUZYKA',
    name: '',
  },
  {
    id: 18,
    title: '',
    name: 'MYFOX14',
  },
  {
    id: 19,
    title: '',
    name: 'LESZEK_SZARY',
  },
  {
    id: 20,
    title: '',
    name: 'BRUDELARGE',
  },
  {
    id: 21,
    title: '',
    name: 'FOTOSHOP',
  },
  {
    id: 22,
    title: 'STWORZONE NA SPECJALNE ZLECENIE EC1 ŁÓDŹ - MIASTO KULTURY',
    name: '',
  },
];
