import React, { FC } from 'react';

import { Text } from '../../Typography/Text';

import {
  ErrorContainer,
  ErrorTitle,
  ErrorImg,
  QR,
  SupportedTitle,
  EnglishText,
} from './styled';

type ErrorProps = {
  error: {
    isMobile: boolean;
    isBrowserSupported: boolean;
  };
};

export const Error: FC<ErrorProps> = ({
  error: { isMobile, isBrowserSupported },
}) => {
  let errorText: string = '';

  if (!isBrowserSupported) errorText = 'TWOJA PRZEGLĄDARKA NIE WSPIERA GRY';
  else if (!isMobile)
    errorText = 'GRA MOŻE DZIAŁAĆ TYLKO NA URZĄDZENIACH MOBILNYCH';

  return (
    <ErrorContainer>
      <ErrorImg src="./icons/errorIcon.svg" alt="Error" />
      <ErrorTitle>WYSTĄPIŁ BŁĄD</ErrorTitle>
      {isBrowserSupported ? (
        <>
          <Text>{errorText}</Text>
          <EnglishText>(It's mobile only experience)</EnglishText>
          <QR src="/img/QR_code.png" alt="QR code" />
        </>
      ) : (
        <>
          <SupportedTitle>UPEWNIJ SIĘ, ŻE UŻYWASZ:</SupportedTitle>
          <Text>CHROME 84+</Text>
          <Text>FIREFOX 79+</Text>
          <Text>SAFARI 13+</Text>
        </>
      )}
    </ErrorContainer>
  );
};
