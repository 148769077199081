import React, { FC } from 'react';
import marked from 'marked';

interface MarkedProps {
  children: string;
}

export const Marked: FC<MarkedProps> = ({ children }) => {
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: children ? marked(children) : '',
      }}
    />
  );
};
