export default {
  colors: {
    white: '#ffffff',
    whiteWithOpacity: 'rgba(255,255,255,0.5)',
    blueGem: '#4A0DB5',
    violentViolet: '#2C0D6D',
    blackRock: '#040432',
    electricViolet: '#7E25FA',
    atoll: '#0C6272',
    matisse: '#198295',
    purpleHeart: '#6835d4',
    purple: '#4500ad',
    blackWithOpacity: 'rgba(0, 0, 0, 0.35)',
    malibu: '#52D4F5',
    torchRed: '#ff1a1a',
  },
};
