import Phaser from 'phaser';

import { GameI } from '../../types/game';

import EdibleItem from '../objects/edibleItem';
import Obstacle from '../objects/obstacle';
import Enemy from '../objects/enemy';

import {
  BACKGROUND_CONFIG,
  HERO_CONFIG,
  CAMERA_OFFSET,
  LVL_POINTS,
  LVL_POINTS_NAME,
} from '../config';

import {
  MANIFEST_IMAGES,
  MANIFEST_SPRITES,
  MANIFEST_SOUNDS,
} from '../manifest';

import Hero from '../objects/hero';
import Background from '../objects/background';
import { BackgroundArgs } from '../../types/background';

import { NavBarData } from '../../types/navbardata';
import { EdibleItemArgs } from '../../types/edibleItem';
import { ObstacleArgs } from '../../types/obstacle';
import { EnemyArgs } from '../../types/enemy';

import {
  createObstacleInstance,
  createEdibleInstance,
  createEnemyInstance,
} from '../../utils/entityGenerator';
import { GameActions } from '../../types/gameActions';
import { debounce } from '../../utils/common';
import { SoundsTypes } from '../../types/sounds';
import SoundManager from '../soundManager';
import { betweenRNG } from '../../utils/rng';

export interface GameArgs {}

export default class Game extends Phaser.Scene implements GameI {
  private hero!: Hero;

  private cursors!: Phaser.Input.InputPlugin;

  static onProgress: (progress: number) => void;

  static onComplete: () => void;

  static updateNavbar: (navset: NavBarData) => void;

  static sendAction: (action: GameActions, data?: Object) => void;

  private edibles: EdibleItem[];

  private obstacles: Obstacle[];

  private enemies: Enemy[];

  private background!: Background;

  private currLvl: number;

  private obstaclesMap: Array<ObstacleArgs>;

  private ediblesMap: Array<EdibleItemArgs>;

  private enemiesMap: Array<EnemyArgs>;

  score: number;

  infLevel: number;

  isInfinityMode: boolean;

  shouldLoadNextLvl: boolean;

  levelIsChanging: boolean;

  background_music!: Phaser.Sound.BaseSound;

  heroSpeed: number;

  private isFirstRun: boolean;

  soundManager: SoundManager;

  constructor(args: GameArgs) {
    super({
      key: 'Game',
      active: true,
    });

    this.edibles = [];
    this.ediblesMap = [];
    this.obstacles = [];
    this.obstaclesMap = [];
    this.enemies = [];
    this.enemiesMap = [];

    this.isInfinityMode = false;
    this.isFirstRun = true;
    this.shouldLoadNextLvl = false;
    this.levelIsChanging = false;

    this.score = 0;
    this.infLevel = 1;
    this.currLvl = 0;
    this.heroSpeed = HERO_CONFIG.velocity.walk;

    this.createEdibleItem = this.createEdibleItem.bind(this);
    this.createObstacle = this.createObstacle.bind(this);
    this.createEnemy = this.createEnemy.bind(this);
    this.switchBackground = this.switchBackground.bind(this);
    this.changeLevel = this.changeLevel.bind(this);

    this.soundManager = SoundManager.getInstance();
  }

  preload() {
    this.load.on('progress', Game.onProgress);
    this.load.on('complete', Game.onComplete);

    MANIFEST_IMAGES.forEach(({ name, path }) => {
      this.load.image(name, path);
    });
    MANIFEST_SPRITES.forEach(({ name, path, options }) => {
      this.load.spritesheet(name, path, options);
    });
    MANIFEST_SOUNDS.forEach(({ name, path }) => {
      this.load.audio(name, path);
    });
  }

  create() {
    this.soundManager.create(this.sound);
    MANIFEST_SOUNDS.forEach(({ name, config }) => {
      this.soundManager.load(name, config);
    });
    this.soundManager.play(SoundsTypes.BG_MUSIC);

    this.cursors = this.input;
    this.hero = new Hero({ ...HERO_CONFIG, scene: this });
    this.hero.follow(this.cameras.main);

    this.preloadLevel();

    this.cameras.main.followOffset.set(
      (this.cameras.main.width / 2) * CAMERA_OFFSET
    );

    this.scale.on('resize', debounce(this.updateBounds.bind(this), 1000));
  }

  update() {
    if (this.shouldLoadNextLvl) {
      this.shouldLoadNextLvl = false;
      this.levelIsChanging = true;
      this.changeLevel();
      this.infLevel += 1;
    }

    if (!this.levelIsChanging) {
      this.hero.update();
      if (this.hero.isAlive()) {
        this.addPointsOnMove();

        if (this.cursors.pointer1.isDown && !this.isHeroAtBounds()) {
          if (this.cursors.activePointer.downElement === this.game.canvas)
            this.hero.jump();
        }
      }

      // LEVEL-END DETECTION
      if (this.isHeroAtBounds()) {
        // Here is level end
        this.hero.go(false);

        if (!this.hero.isJumping || this.isInfinityMode) {
          this.shouldLoadNextLvl = true;

          if (!this.isInfinityMode) {
            Game.sendAction(GameActions.SHOW_ENDING_LEVEL, {
              level: this.currLvl,
            });
          }
        }
      }
    }

    if (this.isFirstRun) {
      this.hero.go(true);
      this.isFirstRun = false;
    }
  }

  private addPointsOnMove() {
    this.score += 0.2;
    Game.updateNavbar({
      score: Math.floor(this.score),
    });
  }

  private removeEdibles() {
    for (let i = 0; i < this.edibles.length; ++i) {
      if (this.edibles[i]) {
        this.edibles[i].bgAsset.destroy();
        this.edibles[i].destroy();
      }
    }
    this.edibles.splice(0, this.edibles.length);
  }

  private removeObstacles() {
    for (let i = 0; i < this.obstacles.length; ++i) {
      this.obstacles[i].destroy();
    }
    this.obstacles.splice(0, this.obstacles.length);
  }

  private removeEnemies() {
    for (let i = 0; i < this.enemies.length; ++i) {
      this.enemies[i].destroy();
    }
    this.enemies.splice(0, this.enemies.length);
  }

  private changeLevel() {
    this.cameras.main.fadeOut(100, 4, 4, 50);

    this.cameras.main.once('camerafadeoutcomplete', () => {
      if (!this.isInfinityMode) {
        if (this.currLvl < 3) {
          this.currLvl += 1;
        }
        if (this.currLvl === 3) {
          this.isInfinityMode = true;
        }
      }

      this.preloadLevel();

      Game.updateNavbar({
        level: this.currLvl,
        score: this.score,
      });
      this.hero.reset();

      if (this.heroSpeed < 700) this.heroSpeed += 30;

      this.hero.go(true, this.heroSpeed);

      this.scene.resume();
      this.cameras.main.fadeIn(100, 4, 4, 50);

      this.levelIsChanging = false;
    });
  }

  private createEdibleItem(edibleConfig: EdibleItemArgs) {
    this.edibles.push(
      new EdibleItem({
        ...edibleConfig,
        scene: this,
      })
    );
  }

  private createObstacle(obstacleConfig: ObstacleArgs) {
    this.obstacles.push(
      new Obstacle({
        ...obstacleConfig,
        scene: this,
      })
    );
  }

  private createEnemy(enemyConfig: EnemyArgs) {
    this.enemies.push(
      new Enemy({
        ...enemyConfig,
        scene: this,
      })
    );
  }

  private addScore(points: number) {
    this.score += points;

    Game.updateNavbar({
      score: this.score,
    });
  }

  private hitHero() {
    if (!this.hero.isInvisible) {
      Game.updateNavbar({
        lives: this.hero.loseLife(),
        score: Math.floor(this.score),
      });
      if (!this.hero.isAlive()) {
        this.time.delayedCall(1000, () => {
          Game.sendAction(GameActions.SHOW_SUMMARY, { hero: this.hero });
        });
      }
    }
  }

  private switchBackground(backgroundConfig: BackgroundArgs): void {
    this.background = new Background({
      ...backgroundConfig,
      scene: this,
    });
  }

  updateBounds() {
    this.cameras?.main?.setBounds(
      0,
      0,
      this.background.width,
      this.background.height
    );

    this.physics?.world?.setBounds(
      0,
      0,
      this.background.width -
        this.cameras.main.width / 2 +
        (this.cameras.main.width / 2) * CAMERA_OFFSET,
      this.background.height
    );
  }

  private makeCollisionWithCollectable() {
    for (let i = 0; i < this.edibles.length; ++i) {
      this.physics.add.overlap(
        this.hero,
        this.edibles[i],
        // @ts-ignore
        this.collisionWithCollectable,
        undefined,
        this
      );
    }
  }

  private makeCollisionWithObstacle() {
    for (let i = 0; i < this.obstacles.length; ++i) {
      this.physics.add.overlap(
        this.hero,
        this.obstacles[i],
        // @ts-ignore
        this.collisionWithObstacle,
        undefined,
        this
      );
    }
  }

  private makeCollisionWithEnemy() {
    for (let i = 0; i < this.enemies.length; ++i) {
      this.physics.add.overlap(
        this.hero,
        this.enemies[i],
        // @ts-ignore
        this.collisionWithEnemy,
        undefined,
        this
      );
    }
  }

  collisionWithCollectable(hero: Hero, edibleItem: EdibleItem) {
    edibleItem.bgAsset.destroy();
    edibleItem.destroy();

    const points = this.add.image(
      hero.x,
      hero.y - 170,
      LVL_POINTS_NAME[this.currLvl]
    );

    this.tweens
      .add({
        targets: points,
        alpha: 0,
        duration: 1000,
        y: hero.body.position.y - 100,
        ease: 'Power2',
      })
      .on('complete', () => {
        points.destroy();
      });

    this.addScore(LVL_POINTS[this.currLvl]);
    this.soundManager.play(SoundsTypes.PICKUP);
  }

  collisionWithObstacle(hero: Hero, obstacle: Obstacle) {
    this.hitHero();
  }

  collisionWithEnemy(hero: Hero, enemy: Enemy) {
    if (
      (hero.body as Phaser.Physics.Arcade.Body).touching.down &&
      (enemy.body as Phaser.Physics.Arcade.Body).touching.up &&
      this.hero.isJumping &&
      this.hero.isAlive()
    ) {
      enemy.destroy();
      const deadEnemy = this.add
        .image(enemy.x, enemy.y, `${enemy.texture.key}_dead`)
        .setScale(0.6)
        .setOrigin(0.5, 1);

      this.tweens
        .add({
          targets: deadEnemy,
          duration: 300,
          alpha: 0,
          ease: 'Power2',
        })
        .on('complete', () => {
          deadEnemy.destroy();
        });

      const points = this.add.image(
        hero.x,
        hero.y - 170,
        LVL_POINTS_NAME[this.currLvl]
      );

      this.tweens
        .add({
          targets: points,
          alpha: 0,
          duration: 1000,
          y: hero.body.position.y - 100,
          ease: 'Power2',
        })
        .on('complete', () => {
          points.destroy();
        });

      this.addScore(LVL_POINTS[this.currLvl] * 3);
      this.hero.jump(-600, true);
    } else this.hitHero();
  }

  private isHeroAtBounds(): boolean {
    return this.hero.x >= this.physics.world.bounds.width;
  }

  private preloadLevel() {
    this.scene.pause();

    this.removeEnemies();
    this.removeEdibles();
    this.removeObstacles();
    this.background?.destroy();

    if (!this.isInfinityMode)
      this.switchBackground(BACKGROUND_CONFIG[this.currLvl]);
    else this.switchBackground(BACKGROUND_CONFIG[betweenRNG(0, 2)]);

    this.updateBounds();

    this.enemiesMap = createEnemyInstance(
      this.currLvl,
      this.background.width,
      this.scale.width
    );
    this.ediblesMap = createEdibleInstance(this.currLvl);
    this.obstaclesMap = createObstacleInstance(this.currLvl);

    this.enemiesMap.forEach(this.createEnemy);
    this.obstaclesMap.forEach(this.createObstacle);
    this.ediblesMap.forEach(this.createEdibleItem);

    this.makeCollisionWithEnemy();
    this.makeCollisionWithCollectable();
    this.makeCollisionWithObstacle();
  }
}
