import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from '../../components/Typography/Text';

import { Container, LoadingAnimation } from './styled';

const LoadingText = styled(Text)`
  margin-bottom: 5rem;
  margin-top: 1rem;
`;

export const Loading: FC = () => (
  <Container>
    <LoadingAnimation />
    <LoadingText>ŁADOWANIE...</LoadingText>
  </Container>
);
