import React, { FC, useRef, useEffect, useState, useCallback } from 'react';

import {
  CreditsTitle,
  CreditsName,
  Container,
  Row,
  Column,
  Logo,
  CreditsDiv,
  Gameboy1,
  Gameboy2,
  Blink,
  Firework1,
  Firework2,
  Person,
} from './styled';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { creditsData } from './creditsData';
import { CREDITS_KEY, CREDITS_VAL } from '../../constants';

type CreditsProps = {
  setHidden: () => void;
};

export const Credits: FC<CreditsProps> = ({ setHidden }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const node = scrollRef.current as any; // eslint-disable-line
    const scrollTime = setInterval(() => {
      node.scrollTop += 1;
    }, 20);
    return () => {
      clearInterval(scrollTime);
    };
  }, []);

  const [isFirstPlay] = useState(
    () => !(localStorage.getItem(CREDITS_KEY) === CREDITS_VAL)
  );
  const handleClick = useCallback(() => {
    setHidden();
    if (isFirstPlay) localStorage.setItem(CREDITS_KEY, CREDITS_VAL);
  }, [isFirstPlay, setHidden]);

  return (
    <Container>
      <Row>
        <Column>
          <Blink>
            <Gameboy1 src="./icons/gameboy_1.svg" />
          </Blink>
          <Firework1 />
        </Column>
        <Column>
          <CreditsDiv ref={scrollRef}>
            <>
              <CreditsTitle>Hop & TRAch!</CreditsTitle>
              <CreditsTitle />
              <CreditsName>
                GRA STWORZONA PRZEZ LIKI MOBILE SOLUTIONS WRAZ ZE WSPÓŁPRACĄ Z
                EC1 ŁÓDŹ
              </CreditsName>
              <Logo
                src="./img/liki-logo.png"
                alt="Logo Liki Mobile Solutions"
              />
              <Logo src="./img/ec1-logo.png" alt="Logo EC1 Łódź" />
              {creditsData.map((data) => (
                <Person key={data.id}>
                  <CreditsTitle>{data.title}</CreditsTitle>
                  <CreditsName>{data.name}</CreditsName>
                </Person>
              ))}
            </>
          </CreditsDiv>
          <PrimaryButton
            text={isFirstPlay ? 'KONTYNUUJ' : 'POWRÓT'}
            type="button"
            withMargin="1rem"
            onClick={handleClick}
          />
        </Column>
        <Column>
          <Firework2 />
          <Blink>
            <Gameboy2 src="./icons/gameboy_2.svg" />
          </Blink>
        </Column>
      </Row>
    </Container>
  );
};
