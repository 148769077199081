import React, { Component } from 'react';

import Game from '../../../game';

import { Loading } from '../../Loading';
import { LevelEnd } from '../../../components/LevelEnd';
import { NavBar } from '../../../components/NavBar';
import { FailSummary } from '../../../components/FailSummary';
import { TapAnywhere } from '../../../components/TapAnywhere';

import { NavBarData } from '../../../types/navbardata';
import { GameActions } from '../../../types/gameActions';
import { LvlI } from '../../../types/game';

import { DEFAULT_NAVBAR_CONFIG } from '../../../game/config';

export interface GameComponentProps {
  handleAttempt: () => void;
}

export interface GameInitialState {
  isLoading: boolean;
  navBar: NavBarData;
  isFailed: boolean;
  isLevelEnd: boolean;
  isFirstRun: boolean;
  currLevel: number;
  isPaused: boolean;
  onLevel3: boolean;
  isMuted: boolean;
}

class GameComponent extends Component<GameComponentProps, GameInitialState> {
  game!: Game;

  constructor(props: GameComponentProps) {
    super(props);
    this.state = {
      isFailed: false,
      isLoading: true,
      isFirstRun: true,
      isLevelEnd: false,
      currLevel: 0,
      isPaused: false,
      navBar: DEFAULT_NAVBAR_CONFIG,
      onLevel3: false,
      isMuted: false,
    };

    this.onUpdateNavbar = this.onUpdateNavbar.bind(this);
    this.onPreloadProgress = this.onPreloadProgress.bind(this);
    this.onPreloadComplete = this.onPreloadComplete.bind(this);
    this.onAction = this.onAction.bind(this);
    this.tapToStart = this.tapToStart.bind(this);
    this.nextLvl = this.nextLvl.bind(this);
    this.onTryAgain = this.onTryAgain.bind(this);
    this.onPlayButtonClick = this.onPlayButtonClick.bind(this);
    this.onMuteClick = this.onMuteClick.bind(this);
  }

  componentDidMount() {
    this.game = new Game({
      onPreloadProgress: this.onPreloadProgress,
      onPreloadComplete: this.onPreloadComplete,
      onUpdateNavbar: this.onUpdateNavbar,
      onAction: this.onAction,
    });
  }

  componentWillUnmount() {
    this.game.exit();
  }

  onPreloadProgress(progress: number) {}

  onPreloadComplete() {
    this.setState({ isLoading: false });
  }

  onUpdateNavbar(navset: NavBarData) {
    this.setState((prev) => {
      return {
        ...prev,
        ...{ navBar: { ...prev.navBar, ...navset } },
      };
    });
  }

  onAction(action: GameActions, data?: Object) {
    switch (action) {
      case GameActions.SHOW_SUMMARY:
        this.setState({ isFailed: true });
        this.game.pause();
        break;
      case GameActions.SHOW_ENDING_LEVEL:
        this.setState({ isLevelEnd: true, currLevel: (data as LvlI).level });
        this.game.pause();
        break;
      case GameActions.PAUSE:
        if (
          !this.state.isFirstRun &&
          !this.state.isFailed &&
          !this.state.isLevelEnd
        ) {
          this.game.pause();
          this.setState({ isPaused: true });
        }
        break;
      case GameActions.RESUME:
        if (
          !this.state.isFirstRun &&
          !this.state.isFailed &&
          !this.state.isLevelEnd
        )
          this.game.resume();
        break;
      case GameActions.MUTE:
        this.setState((prevState) => ({
          isMuted: !prevState.isMuted,
        }));
        this.game.getMuted(!this.state.isMuted);

        if (!this.state.isMuted) {
          this.game.pauseSounds(true);
        } else {
          this.game.resumeSounds();
        }
    }
  }

  onTryAgain() {
    this.props.handleAttempt();
  }

  onPlayButtonClick() {
    this.setState((prevState) => ({
      isLevelEnd: !prevState.isLevelEnd,
    }));
    this.game.resume();
  }

  onMuteClick() {
    this.onAction(GameActions.MUTE);
  }

  tapToStart() {
    if (this.state.isFirstRun) {
      this.setState({ isFirstRun: false });
      this.game.resume();
    } else if (this.state.isPaused) {
      this.setState({ isPaused: false });
      this.game.resume();
    }
  }

  nextLvl() {
    this.setState((prevState) => ({
      isLevelEnd: !prevState.isLevelEnd,
    }));
    if (this.state.currLevel < 2) {
      this.game.resume();
    } else {
      this.setState((prevState) => ({
        onLevel3: !prevState.onLevel3,
        isLevelEnd: true,
      }));
    }
  }

  render() {
    const { isLoading, isFirstRun, isPaused } = this.state;

    return (
      <>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {(isFirstRun || isPaused) && (
              <TapAnywhere
                text={`NACIŚNIJ ABY ${isFirstRun ? 'SKOCZYĆ' : 'KONTYNUOWAĆ'}`}
                iconName="tap-tap.svg"
                onClick={this.tapToStart}
              />
            )}

            <NavBar
              lives={this.state.navBar.lives}
              level={this.state.navBar.level}
              score={Math.floor(this.state.navBar.score)}
              isMuted={this.state.isMuted}
              onClick={this.onMuteClick}
            />
          </>
        )}

        {this.state.isLevelEnd && !this.state.onLevel3 && (
          <LevelEnd level={this.state.currLevel} onClick={this.nextLvl} />
        )}
        {this.state.isLevelEnd && this.state.onLevel3 && (
          <LevelEnd
            level={this.state.currLevel}
            onLevel3={true}
            onPlayButtonClick={this.onPlayButtonClick}
          />
        )}

        {this.state.isFailed && (
          <FailSummary
            level={this.state.currLevel}
            score={Math.floor(this.state.navBar.score)}
            tryAgain={this.onTryAgain}
          />
        )}
        <div id="comic-game" />
      </>
    );
  }
}

export default GameComponent;
