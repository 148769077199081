import React, { FC, useState, useCallback, useEffect, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from '../../routes/Loading/styled';
import {
  Labels,
  LeaderText,
  LeaderList,
  LeaderButtons,
  LeaderboardWrapper,
  LeaderTitle,
} from './styled';

import { PrimaryButton } from '../Buttons/PrimaryButton';
import { Row } from './Row';
import { Credits } from '../Credits';

import { Loading } from '../../routes/Loading';

import { Score } from '../../types/score';
import { getLeaderboard } from '../../api/score';

export const Leaderboard: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCreditsVisible, toggleCreditsVisible] = useState(false);

  const history = useHistory();
  const [leaderboard, setLeaderboard] = useState<Array<Score>>([]);
  const [id] = useState<string | null>(() => localStorage.getItem('userDocID'));

  const toggleCreditsHandler = useCallback(() => {
    toggleCreditsVisible(!isCreditsVisible);
  }, [isCreditsVisible]);

  const onTryAgainButtonClickHandler = (event: MouseEvent) => {
    const path = '/game';
    history.push(path);
  };

  useEffect(() => {
    async function fetchLeaders() {
      const path = '/leaderboard';

      try {
        setLeaderboard(await getLeaderboard(path, id));
        setIsLoading(false);
      } catch (err) {
        history.push('/something-went-wrong');
      }
    }

    fetchLeaders();
  }, [id, history]);

  const trophies = ['gold', 'silver', 'bronze'];

  return (
    <div>
      {isCreditsVisible && <Credits setHidden={toggleCreditsHandler} />}
      {!isLoading ? (
        <Container>
          <LeaderTitle>TABLICA WYNIKÓW</LeaderTitle>
          <Labels>
            <LeaderText>MIEJSCE</LeaderText>
            <LeaderText>WYNIK</LeaderText>
            <LeaderText>NAZWA</LeaderText>
          </Labels>
          <LeaderboardWrapper>
            <LeaderList>
              {leaderboard.map((item, index) => (
                <Row
                  key={item.id}
                  id={item.rank}
                  score={item.score}
                  name={item.name}
                  iconName={index < 3 ? `${trophies[index]}` : ''}
                  primary={item.id === id}
                />
              ))}
            </LeaderList>
          </LeaderboardWrapper>
          <LeaderButtons>
            <PrimaryButton
              text="SPRÓBUJ PONOWNIE"
              onClick={onTryAgainButtonClickHandler}
            />
            <PrimaryButton text="TWÓRCY" onClick={toggleCreditsHandler} />
          </LeaderButtons>
        </Container>
      ) : (
        <Loading />
      )}
    </div>
  );
};
