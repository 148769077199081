import { EnemyI, EnemyArgs } from '../../types/enemy';

export default class Enemy
  extends Phaser.Physics.Arcade.Sprite
  implements EnemyI {
  velocity: number;

  constructor({
    x,
    y,
    texture,
    scene,
    velocity,
  }: EnemyArgs & {
    scene: Phaser.Scene;
  }) {
    super(scene, x, y, texture);
    this.velocity = velocity;

    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    this.setScale(0.6);
    this.setOrigin(0.5, 1);
    this.setVelocityX(velocity);

    this.setCollideWorldBounds(true);
    this.getAnimsArray().forEach((val) => this.scene.anims.create(val));

    this.anims.play(`${this.texture.key} enemyGo`);
  }

  private getAnimsArray(): Array<Phaser.Types.Animations.Animation> {
    return [
      {
        key: `${this.texture.key} enemyGo`,
        frames: this.scene.anims.generateFrameNumbers(this.texture.key, {
          start: 0,
          end: 3,
        }),
        frameRate: 8,
        repeat: -1,
      },
    ];
  }

  move(velocity: number): void {
    this.setVelocity(velocity);
  }
}
