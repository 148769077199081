import { UAParser } from 'ua-parser-js';

export const checkBrowser = (): boolean => {
  const parser: UAParser = new UAParser();
  const browserName: string | undefined = parser.getBrowser().name;
  let browserVersion: number;
  if (parser.getBrowser().version !== undefined) {
    browserVersion = parseInt(parser.getBrowser().version!, 10);
  } else {
    browserVersion = 0;
  }
  if (
    (browserName === 'Chrome' && browserVersion >= 84) ||
    (browserName === 'Firefox' && browserVersion >= 79) ||
    (browserName === 'Mobile Safari' && browserVersion >= 13) ||
    (browserName === 'Chrome WebView' && browserVersion >= 84) ||
    (browserName === 'WebKit' && browserVersion >= 605) ||
    (browserName === 'Facebook' && browserVersion >= 279) ||
    (browserName === 'Samsung Browser' && browserVersion >= 12)
  ) {
    return true;
  }
  return false;
};
