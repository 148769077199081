import React, { FC, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from '../Loading/styled';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import {
  StyledHeaderText,
  StyledIntroText,
  StyledTextWrapper,
  StyledButtonsRow,
  StyledHeroLogo,
  StyledContentWrapper,
  StyledImage,
} from './styled';
import { TUTORIAL_KEY, TUTORIAL_VAL } from '../../constants';
import { introData } from './introData';

export const Intro: FC = () => {
  const [currentScreen, setScreen] = useState(0);

  const history = useHistory();

  const handleNextScreen = useCallback(() => {
    if (currentScreen === 4) {
      if (localStorage.getItem(TUTORIAL_KEY) === TUTORIAL_VAL) {
        history.push('/game');
      } else {
        history.push('/watch-how-to-play');
      }
    } else {
      setScreen((prev) => prev + 1);
    }
  }, [history, currentScreen]);

  const handlePrevScreen = useCallback(() => {
    if (currentScreen !== 0) {
      setScreen((prev) => prev - 1);
    } else {
      history.push('/');
    }
  }, [history, currentScreen]);

  const hasImage = currentScreen > 1 && currentScreen < 4;

  const textAlign = hasImage ? 'left' : 'center';

  const maxWidth =
    currentScreen > 1 && currentScreen < 4 ? '13rem' : '16.25rem';

  const isSecondImage = currentScreen === 3;

  const isLastScreen = currentScreen === 4;

  return (
    <Container>
      <StyledContentWrapper>
        {introData[currentScreen].img.length > 1 && (
          <StyledImage
            src={introData[currentScreen].img}
            order={isSecondImage ? 2 : 1}
          />
        )}
        <StyledTextWrapper hasImage={hasImage} order={isSecondImage ? 1 : 2}>
          {currentScreen < 2 && <StyledHeroLogo src="./heroHead.svg" />}
          {isLastScreen && <StyledHeroLogo src="./img/ec1-logo-white.png" />}
          {introData[currentScreen].header.length > 1 && (
            <StyledHeaderText textAlign={textAlign}>
              {introData[currentScreen].header}
            </StyledHeaderText>
          )}
          <StyledIntroText textAlign={textAlign} maxWidth={maxWidth}>
            {introData[currentScreen].textPt1}
          </StyledIntroText>
          <StyledIntroText textAlign={textAlign} maxWidth={maxWidth}>
            {introData[currentScreen].textPt2}
          </StyledIntroText>
        </StyledTextWrapper>
      </StyledContentWrapper>
      <StyledButtonsRow>
        <PrimaryButton
          text="WRÓĆ"
          withMargin="0 40px 0 0"
          onClick={handlePrevScreen}
        />
        <PrimaryButton
          text={isLastScreen ? 'GRAJ' : 'DALEJ'}
          onClick={handleNextScreen}
        />
      </StyledButtonsRow>
    </Container>
  );
};
