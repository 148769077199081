import React, { FunctionComponent } from 'react';

import { WrapperContainer } from './styled';

type WrapperProps = {
  onClick?: (event: React.MouseEvent) => void;
};

const Wrapper: FunctionComponent<WrapperProps> = (props) => {
  return (
    <WrapperContainer onClick={props.onClick}>
      {props.children}
    </WrapperContainer>
  );
};

Wrapper.displayName = 'Wrapper';

export default Wrapper;
