import { SoundManagerI } from '../types/soundManager';
import { SoundsTypes } from '../types/sounds';

export default class SoundManager implements SoundManagerI {
  private static instance: SoundManager;

  private manager!: Phaser.Sound.BaseSoundManager;

  private soundsList: Record<string, Phaser.Sound.BaseSound>;

  constructor() {
    this.soundsList = {};
  }

  public static getInstance(): SoundManager {
    if (!SoundManager.instance) {
      SoundManager.instance = new SoundManager();
    }

    return SoundManager.instance;
  }

  create(manager: Phaser.Sound.BaseSoundManager) {
    this.manager = manager;
    this.soundsList = {};
  }

  load(name: SoundsTypes, config: Phaser.Types.Sound.SoundConfig) {
    this.soundsList[name] = this.manager.add(name, config);
  }

  play(key: SoundsTypes) {
    this.soundsList[key]?.play();
  }
}
