import { BackgroundI, BackgroundArgs } from '../../types/background';

export default class Background
  extends Phaser.GameObjects.Image
  implements BackgroundI {
  constructor({
    x,
    texture,
    scene,
  }: BackgroundArgs & {
    scene: Phaser.Scene;
  }) {
    super(scene, x, 0, texture);
    this.setDepth(-1);
    this.setOrigin(0, 0);

    this.scene.add.existing(this);
  }
}
