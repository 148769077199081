import React, { FC, useState, useCallback, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { PrimaryButton } from '../Buttons/PrimaryButton';
import { Terms } from './Terms&Conditions';

import {
  EndingScreenContainer,
  EndingText,
  ErrorText,
  NickInput,
  EmailInput,
  EndingButtons,
  InputTitle,
} from './styled';

import { setupVariable } from '../../utils/common';
import { addScore } from '../../api/score';
import { EMAIL_VALIDATION_REGEX } from '../../constants';

type EndingScreenProps = {
  score: number;
  nickname?: string;
  email?: string;
  setHidden: () => void;
};

type EndingValues = {
  score: number;
  nickname: string;
  email: string;
};

export const EndingScreen: FC<EndingScreenProps> = ({
  score = 0,
  nickname = '',
  email = '',
  setHidden,
}) => {
  const history = useHistory();

  const [isTermsAccepted, setTermsAccepted] = useState(false);

  const { handleSubmit, register, errors } = useForm<EndingValues>();

  const callbackOnSubmit = useCallback(
    async (data: EndingValues) => {
      const path = '/leaderboard';
      const requestBody = {
        name: data.nickname,
        score,
        email: data.email,
        base: setupVariable(score, data.nickname),
      };
      try {
        await addScore('/leaderboard', requestBody);
        history.push(path);
      } catch (err) {
        history.push('/something-went-wrong');
      }
    },
    [history, score]
  );
  const onBackButtonClickHandler = (event: MouseEvent) => {
    setHidden();
  };

  const callbackHandleCheck = useCallback(() => {
    setTermsAccepted(!isTermsAccepted);
  }, [isTermsAccepted]);

  return (
    <form onSubmit={handleSubmit(callbackOnSubmit)}>
      <EndingScreenContainer>
        <InputTitle>
          <EndingText>TWÓJ PSEUDONIM</EndingText>
          <ErrorText>{errors.nickname && errors.nickname.message}</ErrorText>
        </InputTitle>
        <NickInput
          name="nickname"
          placeholder="PSEUDONIM"
          ref={register({
            required: '(WYMAGANY)',
            validate: {
              haveSpace: (value) => /^\S*$/i.test(value) || '(USUŃ SPACJE)',
              letters: (value) => /^\S{3,20}$/.test(value) || '(3-20 ZNAKÓW)',
            },
          })}
        />

        <InputTitle>
          <EndingText>ADRES EMAIL</EndingText>
          <ErrorText>{errors.email && errors.email.message}</ErrorText>
        </InputTitle>
        <EmailInput
          name="email"
          placeholder="EMAIL"
          ref={register({
            required: '(WYMAGANY)',
            pattern: {
              value: EMAIL_VALIDATION_REGEX,
              message: '(NIEPOPRAWNY)',
            },
          })}
        />
        <Terms onCheck={callbackHandleCheck} />
        <EndingButtons>
          <PrimaryButton
            type="button"
            text="POWRÓT"
            withMargin="1rem"
            onClick={onBackButtonClickHandler}
          />
          <PrimaryButton
            type="submit"
            text="ZAPISZ"
            withMargin="1rem"
            isBlocked={!isTermsAccepted}
          />
        </EndingButtons>
      </EndingScreenContainer>
    </form>
  );
};
