import styled from 'styled-components';

import { Text } from '../../../Typography/Text';

export const TermsDiv = styled.div`
  text-align: center;
  padding: 0 16px;
`;

export const Label = styled.label`
  position: relative;
`;

export const SmallText = styled(Text)`
  font-size: 0.66rem;
`;
