import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { PrimaryButton } from '../Buttons/PrimaryButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';

import {
  EndingContainer,
  EndingRectangle,
  TextDiv,
  QuoteText,
  Tytus,
} from './styled';

import { ENDING_SCREENS, LEVEL3_ENDING_SCREEN } from './config';

type LevelEndProps = {
  level: number;
  onClick?: (event: MouseEvent) => void;
  onLevel3?: boolean;
  onPlayButtonClick?: (event: MouseEvent) => void;
};

export const LevelEnd: FC<LevelEndProps> = ({
  level,
  onClick,
  onLevel3,
  onPlayButtonClick,
}) =>
  level > 2 ? null : (
    <EndingContainer onClick={onClick}>
      <Tytus src={ENDING_SCREENS[level].src} alt="tytus" />
      <EndingRectangle
        src="assets/img/levelEnding/rectangle.png"
        alt="rectangle"
      />
      {!onLevel3 ? (
        <TextDiv>
          <QuoteText withBottomMargin>{ENDING_SCREENS[level].quote} </QuoteText>
        </TextDiv>
      ) : (
        <TextDiv>
          <QuoteText>{LEVEL3_ENDING_SCREEN}</QuoteText>
          <PrimaryButton
            text="GRAM DALEJ"
            withMargin="0.5rem"
            onClick={onPlayButtonClick}
          />
          <Link to="/">
            <SecondaryButton text="WRÓĆ DO POCZĄTKU" />
          </Link>
        </TextDiv>
      )}
    </EndingContainer>
  );
