export const ENDING_SCREENS = [
  {
    quote:
      'HEJ, ZA TOBĄ PIERWSZY POZIOM. ZNASZ JUŻ STREFĘ GIER RETRO ZE STARYMI KOMPUTERAMI I AUTOMATAMI ORAZ NASZ CHILLOUT. ZAPROWADZĘ CIĘ TERAZ WYŻEJ, GDZIE ROMEK OPOWIE JAK STWORZYĆ PRAWDZIWĄ GRĘ!!!',
    src: 'assets/img/levelEnding/atomek.png',
  },
  {
    quote:
      'HEJ, DRUGI POZIOM ZALICZONY! JUŻ WIESZ, JAK WYGLĄDA NASZA EDUKACYJNA ŚCIEŻKA TWORZENIA GIER KOMPUTEROWYCH ORAZ PIERWSZA CZĘŚĆ STAŁEJ WYSTAWY KOMIKSOWEJ. CHOĆ ZE MNĄ WYŻEJ, GDZIE TYTUS MA DLA CIEBIE NIESPODZIANKĘ!!!',
    src: 'assets/img/levelEnding/romek.png',
  },
  {
    quote:
      'HEJ, ZA TOBĄ OSTATNI, TRZECI POZIOM! ZNASZ ZATEM DRUGĄ CZĘŚĆ WYSTAWY KOMIKSOWEJ ORAZ SALĘ SPOTKAŃ I EKSPOZYCJI CZASOWYCH.',
    src: 'assets/img/levelEnding/tytus.png',
  },
];

export const LEVEL3_ENDING_SCREEN =
  'SPRÓBUJ TERAZ ZEBRAĆ JAK NAJWIĘCEJ PUNKTÓW W BONUSOWEJ RUNDZIE. GRAJ TAK DŁUGO, JAK DASZ RADĘ!!!';
