import styled from 'styled-components';

import { Text } from '../../Typography/Text';

export const TapText = styled(Text)`
  margin-top: 1rem;
  font-size: 30px;
  text-align: center;
`;

export const TapLogo = styled.img`
  user-select: none;
  pointer-events: none;
`;
