import styled, { css } from 'styled-components';

export const StyledRow = styled.div<{ primary?: boolean }>`
  display: flex;
  margin-bottom: 8px;
  justify-content: left;
  width: 80vw;
  padding: 0 0 0 10px;
  align-items: center;

  ${({ primary }) =>
    primary &&
    css`
      flex: 1;
      position: relative;
      color: ${({ theme }) => theme.colors.white};
      border: 2px solid;
      padding: 10px 0 10px 10px;
      border-radius: 12px;
    `}
`;

export const Icon = styled.div`
  flex: 1;
  height: 20px;
  max-width: 33%;
`;

export const Score = styled.div`
  flex: 1;
  max-width: 33%;
`;

export const Name = styled.div`
  flex: 1;
  max-width: 33%;
`;
