import styled from 'styled-components';
import { Text } from '../../../components/Typography/Text';

export const TutorialText = styled(Text)`
  color: ${({ theme }) => theme.colors.electricViolet};
`;

// Temporary frame. Will have to change sizes after mp4
export const TutorialFrame = styled.div`
  width: 70vw;
  max-height: 45vh;
  border-radius: 0.67rem;
  border: solid 2px ${({ theme }) => theme.colors.electricViolet};
  margin-top: 1rem;
  overflow: hidden;
  padding: 0.25rem;

  @media (min-width: 576px) {
    width: 60vw;
  }
  @media (min-width: 768px) {
    width: 50vw;
  }
`;

export const TutorialButtons = styled.div`
  margin-top: 1rem;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  width: 70vw;
  justify-content: center;

  @media (min-width: 576px) {
    justify-content: space-between;
    width: 60vw;
  }
  @media (min-width: 768px) {
    width: 50vw;
  }
`;
