import React, { FC, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { FpsView } from 'react-fps';
import GlobalStyle from '../theme/global-styles';
import theme from '../theme/theme';
import { AppContainer } from './App.styles';
import { Landing } from './Landing';
import { WatchHowToPlay } from './WatchHowToPlay';
import { GameWrapper } from './Game/GameWrapper';
import { NotFound } from './NotFound';
import { ErrorPages } from '../components/ErrorPages';
import { Intro } from './Intro';
import { Tutorial } from './Tutorial';
import { Leaderboard } from '../components/Leaderboard';
import { debounce, updateVh } from '../utils/common';
import { Error500 } from '../components/ErrorPages/Error500';
import { Terms } from './Terms';
import termsData from '../utils/terms';

export const App: FC = () => {
  useEffect(() => {
    updateVh();
    const updateVhDeb = debounce(updateVh, 100);
    window.addEventListener('resize', updateVhDeb);

    return () => {
      window.removeEventListener('resize', updateVhDeb);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {process.env.NODE_ENV === 'development' && (
        <FpsView width={140} height={50} />
      )}
      <Router>
        <ErrorPages>
          <AppContainer>
            <Switch>
              <Route exact path="/">
                <Landing />
              </Route>
              <Route path="/watch-how-to-play">
                <WatchHowToPlay />
              </Route>
              <Route path="/tutorial">
                <Tutorial />
              </Route>
              <Route path="/game">
                <GameWrapper />
              </Route>
              <Route path="/leaderboard">
                <Leaderboard />
              </Route>
              <Route path="/intro">
                <Intro />
              </Route>
              <Route path="/something-went-wrong">
                <Error500 />
              </Route>
              <Route path="/privacy-policy">
                <Terms {...termsData.privacy} />
              </Route>
              <Route path="/terms-and-conditions">
                <Terms {...termsData.terms} />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </AppContainer>
        </ErrorPages>
      </Router>
    </ThemeProvider>
  );
};
