import styled from 'styled-components';

import { Text } from '../../Typography/Text';

type Props = {
  withBottomMargin?: boolean;
};

export const EndingContainer = styled.div`
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EndingRectangle = styled.img`
  max-width: 52vw;
  height: auto;
  pointer-events: none;
`;

export const TextDiv = styled.div`
  padding-right: 1rem;
  padding-bottom: 1rem;
  max-width: 40vw;
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Tytus = styled.img`
  max-width: 26vw;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

export const QuoteText = styled(Text)<Props>`
  display: block;
  color: black;
  line-height: 1rem;
  text-align: center;
  margin-bottom: ${({ withBottomMargin }) =>
    withBottomMargin ? '1.5rem' : '0'};

  @media (max-width: 826px) {
    font-size: 0.5rem;
    line-height: 0.75rem;
  }

  @media (max-width: 672px) {
    font-size: 0.4rem;
    line-height: 0.5rem;
  }
`;
