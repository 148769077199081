import React, { FC } from 'react';

import { Hearts } from './Hearts';
import { MuteButton } from './MuteButton';
import { Text } from '../Typography/Text';

import { Header, ScoreText, NavText, ScoreDiv } from './styled';

import { NavBarData } from '../../types/navbardata';
import { LVL_NAME } from '../../game/config';

export const NavBar: FC<NavBarData> = ({
  lives = 3,
  level = 0,
  score = 0,
  isMuted,
  onClick,
}) => (
  <>
    <Header>
      <Hearts lives={lives} />
      <Text>{LVL_NAME[level]}</Text>
      <ScoreDiv>
        <NavText>WYNIK </NavText>
        <ScoreText>{score}</ScoreText>
      </ScoreDiv>
    </Header>
    <MuteButton muted={isMuted} onClick={onClick} />
  </>
);
