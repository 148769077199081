import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Container } from '../Loading/styled';
import { Text } from '../../components/Typography/Text';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../components/Buttons/SecondaryButton';

import { Buttons } from './styled';

import { TUTORIAL_KEY, TUTORIAL_VAL } from '../../constants';

const WatchText = styled(Text)`
  max-width: 17.4375rem;
  text-align: center;
`;

export const WatchHowToPlay: FC = () => {
  const history = useHistory();

  const onYesButtonClick = React.useCallback(() => {
    history.push('/tutorial');
  }, [history]);

  const onNoButtonClick = React.useCallback(() => {
    history.push('/game');
  }, [history]);

  const onNoAgainButtonClick = React.useCallback(() => {
    localStorage.setItem(TUTORIAL_KEY, TUTORIAL_VAL);
    history.push('/game');
  }, [history]);

  return (
    <Container>
      <WatchText>CZY CHCESZ ZOBACZYĆ SAMOUCZEK?</WatchText>

      <Buttons>
        <PrimaryButton
          text="NIE, DZIĘKUJĘ"
          withMargin="0.5rem"
          onClick={onNoButtonClick}
        />
        <PrimaryButton
          text="TAK, POPROSZĘ"
          withMargin="0.5rem"
          onClick={onYesButtonClick}
        />
      </Buttons>

      <SecondaryButton
        text="NIE I NIE PYTAJ PONOWNIE"
        onClick={onNoAgainButtonClick}
      />
    </Container>
  );
};
