import styled, { keyframes } from 'styled-components';

import { Text } from '../../Typography/Text';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  z-index: 4;
  background-color: ${({ theme }) => theme.colors.blackRock};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
`;

export const Logo = styled.img`
  margin-bottom: 1rem;
  max-width: 50%;
  height: auto;
  align-self: center;
  pointer-events: none;
  user-select: none;
`;

export const CreditsDiv = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: 53vh;
  overflow: scroll;
  touch-action: pan-y;
`;

export const Gameboy1 = styled.img`
  margin-top: 1rem;
  margin-bottom: 1rem;
  transform: rotate(-15deg);
  z-index: 1;
  pointer-events: none;
  user-select: none;
`;

export const Gameboy2 = styled(Gameboy1)`
  transform: rotate(15deg);
`;

const animateX = keyframes`
  100% { background-position-x: -196px;}
`;

const animateY = keyframes`
  100% { background-position-y: -196px;}
`;

export const Blink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
  background: transparent url('/img/blink.png') 0 0 no-repeat;
  animation: ${animateX} 0.4s steps(2) infinite,
    ${animateY} 0.8s steps(1) infinite;
  z-index: 0;
  pointer-events: none;
  user-select: none;
`;

export const firework = keyframes`
  100% { background-position-x: -1248px;}
`;

export const Firework1 = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: 96px;
  width: 96px;
  background: transparent url('/img/aqua.png') 0 0 no-repeat;
  animation: ${firework} 1.3s steps(13) infinite;
  align-self: flex-end;
  pointer-events: none;
  user-select: none;
`;

export const Firework2 = styled(Firework1)`
  margin-top: 1rem;
  background: transparent url('/img/purple.png') 0 0 no-repeat;
  animation: ${firework} 1.6s steps(13) infinite;
  align-self: flex-start;
`;
export const Person = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const CreditsTitle = styled(Text)`
  font-family: 'JoystixMonospace';
  font-size: 0.5rem;
  text-align: center;
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.colors.electricViolet};
  user-select: none;
`;

export const CreditsName = styled(Text)`
  font-family: 'JoystixMonospace';
  font-size: 0.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0.8rem;
  user-select: none;
`;
