import styled from 'styled-components';

export const Buttons = styled.div`
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  display: flex;
`;
