import Phaser from 'phaser';

// Randomize number from x to y
export const betweenRNG = (x: number, y: number): number => {
  return Phaser.Math.RND.between(x, y);
};

// return true or false
export const shouldRNG = (chance: number): boolean => {
  return Phaser.Math.RND.frac() < chance;
};
