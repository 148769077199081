import styled from 'styled-components';

import { Text } from '../../../components/Typography/Text';

type StyledProps = {
  order: number;
  textAlign: string;
  maxWidth: string;
  hasImage: boolean;
};

export const StyledHeaderText = styled(Text)<{
  textAlign: StyledProps['textAlign'];
}>`
  font-size: 2.5vh;
  color: ${({ theme }) => theme.colors.electricViolet};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: 0.75rem;
`;

export const StyledIntroText = styled(StyledHeaderText)<{
  maxWidth: StyledProps['maxWidth'];
}>`
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledTextWrapper = styled.div<{
  order: StyledProps['order'];
  hasImage: StyledProps['hasImage'];
}>`
  margin-bottom: ${({ hasImage }) => (hasImage ? '0' : '2.5rem')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: ${({ order }) => order};
`;

export const StyledButtonsRow = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  align-items: center;
  align-self: flex-end;
  left: 0;
  bottom: 10vh;
`;

export const StyledHeroLogo = styled.img`
  margin-bottom: 0.75rem;
  max-width: 3rem;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

export const StyledImage = styled.img<{ order: StyledProps['order'] }>`
  order: ${({ order }) => order};
  margin: ${({ order }) =>
    order === 2 ? '0 0 1.375rem 2rem' : '0 2rem 1.375rem 0'};
  max-width: 100%;
  height: 40vh;
`;
