import React, { FC } from 'react';

import {
  Button,
  SmallRectangleReplay,
  MiddleRectangleReplay,
  ShadowBox,
  ColorBoxReplay,
  Logo,
} from './styled';

type IconButtonProps = {
  iconName: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const IconButton: FC<IconButtonProps> = ({ iconName, onClick }) => (
  <Button onClick={onClick}>
    <SmallRectangleReplay />
    <MiddleRectangleReplay />
    <ShadowBox>
      <ColorBoxReplay>
        <Logo>
          <img src={`/icons/${iconName}`} alt="replay" />
        </Logo>
      </ColorBoxReplay>
    </ShadowBox>
  </Button>
);
