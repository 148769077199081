import React, { FC } from 'react';

import Wrapper from '../Wrapper';

import { TapText, TapLogo } from './styled';

type TapAnywhereProps = {
  iconName: string;
  onClick?: (event: React.MouseEvent) => void;
  text: string;
};

export const TapAnywhere: FC<TapAnywhereProps> = ({
  iconName,
  onClick,
  text,
}) => (
  <Wrapper onClick={onClick}>
    <TapLogo src={`/icons/${iconName}`} alt="tap" />
    <TapText stroke={3}>{text}</TapText>
  </Wrapper>
);
