import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blackRock};
  position: absolute;
  top: 0;
  left: 0;
`;

export const LoadingTitle = styled.h1`
  font-family: 'JoystixMonospace';
  font-size: 0.66rem;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;

const animate = keyframes`
  100% {  background-position: -512px 0 ; }
`;

export const LoadingAnimation = styled.div`
  margin-top: 1rem;
  width: 64px;
  height: 88px;
  background: transparent url('/img/loading.png') 0 0 no-repeat;
  animation: ${animate} 0.8s steps(8) infinite;
`;
