import React, { FC } from 'react';

import { Marked } from '../../components/Marked';

import {
  BodyText,
  Container,
  HeaderText,
  ScrollDiv,
  SubtitleText,
} from './styled';

export type TermsProps = {
  title: string;
  subtitle: string;
  body: string;
};

export const Terms: FC<TermsProps> = ({ title, subtitle, body }) => (
  <ScrollDiv>
    <Container>
      <HeaderText>{title}</HeaderText>
      <SubtitleText>{subtitle}</SubtitleText>
      <BodyText>
        <Marked>{body}</Marked>
      </BodyText>
    </Container>
  </ScrollDiv>
);
