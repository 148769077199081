import styled from 'styled-components';

import { Text } from '../../../Typography/Text';
import { Container } from '../../../../routes/Loading/styled';

export const ErrorContainer = styled(Container)`
  padding: 0 1rem;
  z-index: 3;
  text-align: center;
`;

export const ErrorTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.malibu};
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const EnglishText = styled(Text)`
  margin-top: 1rem;
`;

export const SupportedTitle = styled(Text)`
  margin-bottom: 0.65rem;
`;

export const ErrorImg = styled.img`
  margin-bottom: 1rem;
`;

export const QR = styled.img`
  margin-top: 1rem;
  border: 5px solid ${({ theme }) => theme.colors.malibu};
  border-radius: 1rem;
  width: 8rem;
  height: 8rem;
`;
