import React, { FC } from 'react';

import { Text } from '../../Typography/Text';

import { OrientationContainer, OrientationErrorImg } from './styled';

export const OrientationError: FC = () => (
  <OrientationContainer>
    <Text>OBRÓĆ TELEFON DO POZYCJI HORYZONTALNEJ</Text>
    <OrientationErrorImg src="./icons/landscape.svg" alt="Landscape phone" />
  </OrientationContainer>
);
