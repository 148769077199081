import styled from 'styled-components';

export const StyledSecondaryButton = styled.button`
  height: 0.8rem;
  font-family: 'JoystixMonospace';
  font-size: 0.66rem;
  text-align: center;
  text-decoration: underline;
  color: ${({ theme, color }) => color || theme.colors.purpleHeart};
  cursor: pointer;
  background-color: Transparent;
  outline: none;
  box-shadow: none;
  border: none;
  user-select: none;

  @media (max-width: 470px) {
    font-size: 0.4rem;
  }
`;
