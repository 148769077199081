import styled from 'styled-components';

import { Text } from '../../Typography/Text';

export const Buttons = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  display: flex;
  width: 50vw;
  margin-top: 1rem;
`;

export const LeaderTitle = styled(Text)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.malibu};
`;

export const Labels = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  display: flex;
  width: 80vw;
  padding: 0 1rem 0 2rem;

  @media (max-width: 470px) {
    font-size: 0.4rem;
  }

  @media (max-height: 500px) {
    margin-right: 0.5rem;
  }
`;

export const LeaderText = styled(Text)`
  color: ${({ theme }) => theme.colors.whiteWithOpacity};
  font-size: 0.5rem;
  flex: 1;
  text-align: left;

  @media (max-width: 470px) {
    font-size: 0.4rem;
  }
`;

export const LeaderList = styled.ol`
  color: ${({ theme }) => theme.colors.electricViolet};
  font-family: 'JoystixMonospace';
  font-size: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  user-select: none;
  pointer-events: none;
  padding-right: 0.5rem;

  @media (max-width: 470px) {
    font-size: 0.4rem;
  }
`;

export const LeaderboardWrapper = styled.div`
  overflow-x: hidden;
  min-height: 40px;

  @media (max-height: 500px) {
    overflow-y: scroll;
    touch-action: pan-y;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.6rem;
    height: 1.16rem;
    background-color: ${({ theme }) => theme.colors.electricViolet};
    border-radius: 0.29rem;
  }

  &::-webkit-scrollbar-track {
    overflow: auto;
    background-color: white;
    border: 0.25rem solid ${({ theme }) => theme.colors.blackRock};
  }
`;

export const LeaderButtons = styled(Buttons)`
  margin-left: 2rem;
  display: flex;
  width: 80vw;
  align-items: center;
`;
