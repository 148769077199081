import React, { FC, useState, useCallback } from 'react';

import { TermsDiv, Label, SmallText } from './styled';
import { Checkbox } from './Checkbox';
import { LinkButton } from '../../Buttons/LinkButton';
import { TermsConditions } from '../../TermsConditions';

type TermsProps = {
  onCheck: () => void;
};

export const Terms: FC<TermsProps> = ({ onCheck }) => {
  const [checked, setChecked] = useState(false);
  const [isTermsShowed, setTermsShowed] = useState(false);

  const callbackHandleCheckboxChange = useCallback(() => {
    setChecked(!checked);
    onCheck();
  }, [checked, onCheck]);

  const callbackShowTerms = useCallback(() => {
    setTermsShowed(!isTermsShowed);
  }, [isTermsShowed]);

  return (
    <>
      {isTermsShowed ? (
        <TermsConditions isShown={callbackShowTerms} />
      ) : (
        <TermsDiv>
          <Label>
            <Checkbox
              checked={checked}
              onChange={callbackHandleCheckboxChange}
            />

            <SmallText>PRZECZYTAŁEM/AM I AKCEPTUJĘ</SmallText>
          </Label>
          <LinkButton
            href="/privacy-policy"
            title="Polityka prywatności"
            text="POLITYKĘ PRYWATNOŚCI"
          />
          <SmallText>I</SmallText>
          <LinkButton
            href="/terms-and-conditions"
            title="Regulamin"
            text="REGULAMIN"
          />
        </TermsDiv>
      )}
    </>
  );
};
