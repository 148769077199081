import * as RNG from './rng';
import { ObstacleArgs } from '../types/obstacle';
import { EdibleItemArgs } from '../types/edibleItem';
import { EnemyArgs } from '../types/enemy';
import { RANDOM_GENERATOR_CONFIG } from './generatorConfig';
import { HERO_CONFIG } from '../game/config';

function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generatePosX(bgWidth: number, cameraWidth: number): number[] {
  let x = RANDOM_GENERATOR_CONFIG.firstItemX;
  let d = 0;
  const xArray = [];
  while (
    x <
    bgWidth - Math.floor(cameraWidth + RANDOM_GENERATOR_CONFIG.itemDistanceMax)
  ) {
    d = randomInteger(
      RANDOM_GENERATOR_CONFIG.itemDistanceMin,
      RANDOM_GENERATOR_CONFIG.itemDistanceMax
    );

    x += d;
    xArray.push(x);
  }
  return xArray;
}

let xArr: Array<number> = [];
let xArrBeforeSub: Array<number> = [];

export const createObstacleInstance = (level: number): Array<ObstacleArgs> => {
  let x = 0;
  let shouldSpawn = false;
  let obstacleInstance: ObstacleArgs;
  const obstacleInstances: Array<ObstacleArgs> = [];

  const obstacleName = [
    'obstacle_box',
    'obstacle_banana',
    'obstacle_bin',
    'obstacle_bin_trash',
    'obstacle_liquid',
  ];

  for (let i = 0; i < xArr.length; i++) {
    x = xArr[i];
    shouldSpawn = RNG.shouldRNG(
      RANDOM_GENERATOR_CONFIG.spawnObstacleChance[level]
    );

    if (shouldSpawn) {
      obstacleInstance = {
        x,
        y: 445,
        texture:
          level === 0
            ? obstacleName[RNG.betweenRNG(0, obstacleName.length - 3)]
            : obstacleName[RNG.betweenRNG(0, obstacleName.length - 1)],
      };
      obstacleInstances.push(obstacleInstance);
    }
  }
  return obstacleInstances;
};

export const createEdibleInstance = (level: number): Array<EdibleItemArgs> => {
  let x = 0;
  let shouldGroupSpawn = false;
  let howManyItems = 0;
  let itemsSpan = 0;
  let groupHight = 0;
  let edibleInstance: EdibleItemArgs;
  const edibleInstances: Array<EdibleItemArgs> = [];

  const chancedForGroup = [1, 2, 3];
  const edibleName = [
    'pen_1',
    'pen_2',
    'pen_3',
    'compass',
    'setsquare',
    'gameboy_1',
    'gameboy_2',
    'ruler',
    'book_1',
    'book_2',
    'book_3',
    'book_4',
  ];

  for (let j = 0; j < xArrBeforeSub.length; j++) {
    shouldGroupSpawn = RNG.shouldRNG(
      RANDOM_GENERATOR_CONFIG.spawnCollectableChance[level]
    );
    x = xArrBeforeSub[j];

    if (shouldGroupSpawn) {
      howManyItems = Phaser.Math.RND.weightedPick(chancedForGroup);
      itemsSpan = RNG.betweenRNG(70, 120);
      groupHight = RNG.betweenRNG(190, 240);

      let hights = [groupHight, groupHight, groupHight];
      let Xs = [x, x, x];

      if (howManyItems === 2) {
        Xs = [x - (itemsSpan / 2 + 10), x + (itemsSpan / 2 + 10), x];
      }

      if (howManyItems === 3) {
        hights = [groupHight + 50, groupHight, groupHight + 50];
        Xs = [x - itemsSpan, x, x + itemsSpan];
      }

      for (let i = 0; i < howManyItems; ++i) {
        edibleInstance = {
          x: Xs[i],
          y: hights[i],
          score: 100,
          texture: edibleName[RNG.betweenRNG(0, edibleName.length - 1)],
          bgTexture: 'star',
        };
        edibleInstances.push(edibleInstance);
      }
    }
  }
  return edibleInstances;
};

export const createEnemyInstance = (
  level: number,
  bgWidth: number,
  cameraWidth: number
): Array<EnemyArgs> => {
  let enemyInstance: EnemyArgs;
  const enemyInstances: Array<EnemyArgs> = [];

  xArr = generatePosX(bgWidth, cameraWidth);
  xArrBeforeSub = xArr;

  const howManySpawns = RANDOM_GENERATOR_CONFIG.spawnEnemyChance[level];
  const Start = bgWidth / 4 - howManySpawns * 400;
  const Finish = bgWidth - 1000;
  const Distance = (Finish - Start) / howManySpawns;

  const enemyNames = ['robot_1', 'robot_2', 'robot_3'];

  for (let i = 0; i < howManySpawns; ++i) {
    const enemyTexture = RNG.betweenRNG(0, enemyNames.length - 1);
    enemyInstance = {
      x: RNG.betweenRNG(
        Start + i * Distance + 400,
        Finish - (howManySpawns - 1 - i) * Distance
      ),
      y: 450,
      texture: enemyNames[enemyTexture],
      velocity: -RANDOM_GENERATOR_CONFIG.enemyVelocity[level],
    };

    const d = calcDistance(HERO_CONFIG.x, enemyInstance.x);

    let Sh = Math.floor(
      (HERO_CONFIG.velocity.walk * d) /
        (RANDOM_GENERATOR_CONFIG.enemyVelocity[level] +
          HERO_CONFIG.velocity.walk)
    );

    if (level === 0) Sh += 200;
    else Sh += 500;

    const filteredElements = xArr.map((x) => {
      const isBefore = x >= Sh - 150 && x <= Sh + 100;
      const isCurrent = x >= Sh + 100 && x <= Sh + 500;
      const isAfter = x >= Sh + 500 && x <= Sh + 800 + 150 * level;

      if (isBefore || isCurrent || isAfter) {
        return null;
      }
      return x;
    });
    const temp = [];
    for (const j of filteredElements) j && temp.push(j);

    xArr = temp;

    enemyInstances.push(enemyInstance);
  }

  return enemyInstances;
};

const calcDistance = (x1: number, x2: number) => {
  return Math.abs(x1 - x2);
};
