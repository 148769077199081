import { createGlobalStyle } from 'styled-components';
import { UAParser } from 'ua-parser-js';

const parser = new UAParser();
const browserName = parser.getBrowser().name;

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    touch-action: none;
    background-color: #040432;
  }

  html,
  body {
    font-size: 24px;
    z-index: 0;
    height: ${browserName === 'Mobile Safari' ? '100vh' : '100%'};
  }

  body {
    margin: 0;
  }

  #root {
    z-index: 1;
  }

  @font-face {
    font-family: 'JoystixMonospace';
    src: url('/fonts/joystixmonospace.ttf');
  }

  @media screen and (max-device-width: 480px){
  body{
    -webkit-text-size-adjust: none;
  }
}
`;

export default GlobalStyle;
