import styled from 'styled-components';

type Props = {
  checked?: boolean;
};

export const CheckboxContainer = styled.div`
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  display: inline-block;
  vertical-align: middle;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 20px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  opacity: 0;
`;

export const Icon = styled.svg`
  fill: ${({ theme }) => theme.colors.electricViolet};
  stroke: none;
`;

export const StyledCheckbox = styled.div<Props>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: ${({ theme }) => theme.colors.blackRock};
  border: solid 2px ${({ theme }) => theme.colors.electricViolet};
  border-radius: 0.33rem;
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
