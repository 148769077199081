import React, { FC } from 'react';

import { StyledSecondaryButton } from './styled';

type SecondaryButtonProps = {
  text: string;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

export const SecondaryButton: FC<SecondaryButtonProps> = ({
  text,
  color,
  onClick,
  type,
}) => (
  <StyledSecondaryButton type={type} color={color} onClick={onClick}>
    {text}
  </StyledSecondaryButton>
);
