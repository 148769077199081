import theme from '../theme/theme';
import { NavBarData } from '../types/navbardata';
import { HeroArgs } from '../types/hero';
import { VibrationConfig } from '../types/vibration';

export const GAME_CONFIG = {
  type: Phaser.CANVAS,
  parent: 'comic-game',
  backgroundColor: theme.colors.blackRock,
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
      gravity: { y: 2500 },
    },
  },
  input: {
    touch: true,
    activePointer: 0,
  },
};

export const DEFAULT_WIDTH: number = 700;
export const DEFAULT_HEIGHT: number = 450;
export const MAX_WIDTH: number = DEFAULT_WIDTH * 4;
export const MAX_HEIGHT: number = DEFAULT_HEIGHT * 1.5;

// position of hero in viewport -> 0 is a center
// range <-1,1> -> example viewport ->  -1|.....0.....1|
// -1 is out of the screen, because hero is drawing from right,bottom
export const CAMERA_OFFSET = -0.65;

export const DEFAULT_NAVBAR_CONFIG: NavBarData = {
  lives: 3,
  level: 0,
  score: 0,
};

export const VIBRATION_CONFIG: VibrationConfig = {
  hit: 350,
  dead: 1350,
};

export const HERO_CONFIG: HeroArgs = {
  x: 200,
  y: 450,
  lifePoints: 3,
  name: '',
  email: '',
  texture: 'hero',
  scale: 0.6,
  velocity: {
    walk: 370,
    jump: -1000,
  },
  invisible: {
    time: 3000,
    tickCount: 20,
  },
  messageTime: 2000, // <500,...> ms
};

export const BACKGROUND_CONFIG = [
  {
    x: 0,
    texture: 'level1_bg',
  },
  {
    x: 0,
    texture: 'level2_bg',
  },
  {
    x: 0,
    texture: 'level3_bg',
  },
  {
    x: 0,
    texture: 'level1_bg',
  },
];

export const LVL_NAME = [
  '0. PIĘTRO',
  '1. PIĘTRO',
  '2. PIĘTRO',
  'TRYB NIESKOŃCZONY',
];

export const LVL_POINTS = [100, 200, 300, 100];

export const LVL_POINTS_NAME = [
  '100points',
  '200points',
  '300points',
  '100points',
];

export enum MessageType {
  HIT,
  LUCK,
  WIN,
}

export const HERO_MESSAGES = {
  [MessageType.HIT]: [
    'ouch',
    'face',
    'oh_no',
    'ojoj',
    'random',
    'oops',
    'wrrr',
  ],
  [MessageType.LUCK]: ['uff'],
  [MessageType.WIN]: ['made_it'],
};
