export const has = (obj: object, key: string) =>
  obj && key && Object.prototype.hasOwnProperty.call(obj, key);

export const isFunction = (func: unknown): boolean =>
  typeof func === 'function';

// debounce from ts-debounce
export type Procedure = (...args: any[]) => void;

export type Options = {
  isImmediate: boolean;
};

export const setupVariable = (base: number, delta: string): string => {
  const id: string = 'shlUIGKPLx';
  const baseArr: number[] = Array.from((base * delta.length).toString()).map(
    Number
  );
  let convertedTab: string = '';

  for (let i = 0; i < baseArr.length; ++i) {
    convertedTab += id[baseArr[i]];
  }

  return convertedTab;
};

export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false,
  }
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  // eslint-disable-next-line func-names
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this;

    const doLater = () => {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    };

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  };
}

export const updateVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};