import styled from 'styled-components';

import { Container } from '../../../routes/Loading/styled';
import { Text } from '../../Typography/Text';
import { Buttons } from '../../../routes/WatchHowToPlay/styled';

export const EndingScreenContainer = styled(Container)`
  position: absolute;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.blackRock};
`;

export const EndingInput = styled.input`
  font-family: 'JoystixMonospace';
  size: 1rem;
  background-color: Transparent;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  height: 1.67rem;
  border: solid 2px ${({ theme }) => theme.colors.electricViolet};
  border-radius: 10px;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  flex-shrink: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.whiteWithOpacity};
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.blackRock} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.blackRock} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
  }
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.torchRed};
  margin-left: 0.5rem;
`;

export const EndingText = styled(Text)`
  color: ${({ theme }) => theme.colors.electricViolet};
`;

export const NickInput = styled(EndingInput)`
  width: 30vw;
`;

export const EmailInput = styled(EndingInput)`
  width: 50vw;
`;

export const InputTitle = styled.div`
  display: flex;
`;

export const EndingButtons = styled(Buttons)`
  margin-top: 0.5rem;
  margin-bottom: 0;

  @media (max-width: 600px) {
    margin-top: 0.1rem;
  }
`;
