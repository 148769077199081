import styled from 'styled-components';

export const MuteContainer = styled.div`
  position: absolute;
  transform: translateY(-20%);
  height: 1.625rem;
  left: 95%;
  transform: translateX(-95%);
  display: flex;
  align-items: center;
  width: 7vw;
  background-color: ${({ theme }) => theme.colors.purpleHeart};
  justify-content: center;
  border-bottom-left-radius: 0.417rem;
  border-bottom-right-radius: 0.417rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: solid 2px ${({ theme }) => theme.colors.purple};
`;
