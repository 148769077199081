import styled from 'styled-components';

import { Container } from '../../Loading/styled';
import { Text } from '../../../components/Typography/Text';

export const LandingContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.blackRock};
  position: relative;
`;

export const CookiesTextMalibu = styled(Text)`
  color: ${({ theme }) => theme.colors.malibu};
  font-size: 0.45rem;
`;

export const CookiesText = styled(Text)`
  font-size: 0.45rem;
  display: block;
  margin: 1.25rem 2rem 0.75rem 2rem;
  line-height: 0.85rem;
  text-align: center;
  text-shadow: 1px 1px 4px ${({ theme }) => theme.colors.blackRock};
`;

export const View = styled.div`
  height: 100%;
  width: 100%;
`;

export const Img = styled.img`
  pointer-events: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Overlap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100vw;
`;
