import React, { FC, useState, useCallback } from 'react';

import {
  Container,
  FailText,
  Score,
  ButtonsContainer,
  ContentWrapper,
} from './styled';

import { PrimaryButton } from '../Buttons/PrimaryButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { EndingScreen } from '../EndingScreen';
import { Credits } from '../Credits';

import { CREDITS_KEY, CREDITS_VAL } from '../../constants';

type FailSummaryProps = {
  saveVisible?: boolean;
  creditsVisible?: boolean;
  score?: number;
  level: number;
  tryAgain: () => void;
};

export const FailSummary: FC<FailSummaryProps> = ({
  score = 0,
  saveVisible = false,
  creditsVisible = false,
  tryAgain,
  level,
}) => {
  const [isSaveScoreVisible, toggleSaveScoreVisible] = useState(saveVisible);
  const [isCreditsVisible, toggleCreditsVisible] = useState(creditsVisible);

  const toggleSaveHandler = useCallback(() => {
    toggleSaveScoreVisible(!isSaveScoreVisible);
  }, [isSaveScoreVisible]);

  const toggleCreditsHandler = useCallback(() => {
    toggleCreditsVisible(!isCreditsVisible);
  }, [isCreditsVisible]);

  const isEndless = level >= 2;

  const showCredits = !(localStorage.getItem(CREDITS_KEY) === CREDITS_VAL);

  const onContinueButtonClickHandler = () => {
    toggleCreditsHandler();
  };

  return (
    <div>
      {isCreditsVisible && <Credits setHidden={toggleCreditsHandler} />}
      {isSaveScoreVisible && (
        <EndingScreen score={score} setHidden={toggleSaveHandler} />
      )}
      {!isSaveScoreVisible && !isCreditsVisible ? (
        <div>
          <Container>
            <ContentWrapper>
              <FailText>OOPS... WYGLĄDA NA TO, ŻE NIE MASZ WIĘCEJ ŻYĆ</FailText>
              <FailText>TWÓJ WYNIK</FailText>
              <Score>{score}</Score>
              <>
                {showCredits && (
                  <ButtonsContainer>
                    <PrimaryButton
                      text="KONTYNUUJ"
                      onClick={onContinueButtonClickHandler}
                    />
                  </ButtonsContainer>
                )}
                {!showCredits && !isEndless && (
                  <>
                    <ButtonsContainer>
                      <PrimaryButton
                        text="SPRÓBUJ PONOWNIE"
                        withMargin="1rem"
                        onClick={tryAgain}
                      />
                    </ButtonsContainer>
                    <SecondaryButton
                      text="TWÓRCY"
                      onClick={toggleCreditsHandler}
                    />
                  </>
                )}
                {!showCredits && isEndless && (
                  <>
                    <ButtonsContainer>
                      <PrimaryButton
                        text="SPRÓBUJ PONOWNIE"
                        withMargin="1rem"
                        onClick={tryAgain}
                      />
                      <PrimaryButton
                        text="ZAPISZ WYNIK"
                        withMargin="1rem"
                        onClick={toggleSaveHandler}
                      />
                    </ButtonsContainer>
                    <SecondaryButton
                      text="TWÓRCY"
                      onClick={toggleCreditsHandler}
                    />
                  </>
                )}
              </>
            </ContentWrapper>
          </Container>
        </div>
      ) : null}
    </div>
  );
};
