import { ObstacleI, ObstacleArgs } from '../../types/obstacle';

export default class Obstacle
  extends Phaser.Physics.Arcade.Image
  implements ObstacleI {
  constructor({
    x,
    y,
    texture,
    scene,
  }: ObstacleArgs & {
    scene: Phaser.Scene;
  }) {
    super(scene, x, y, texture);

    this.setOrigin(0.5, 1);
    this.setScale(1.2);

    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    (this.body as Phaser.Physics.Arcade.Body).setAllowGravity(false);
  }
}
