import styled, { css } from 'styled-components';

interface TextProps {
  stroke?: number;
}

export const Text = styled.span<TextProps>`
  font-family: 'JoystixMonospace';
  font-size: 16px;
  user-select: none;

  color: ${({ theme }) => theme.colors.white};
  ${({ stroke, theme }) =>
    stroke &&
    css`
      text-shadow: -${stroke}px -${stroke}px 0 ${theme.colors.electricViolet},
        0 ${stroke}px 0 ${theme.colors.electricViolet},
        ${stroke}px -${stroke}px 0 ${theme.colors.electricViolet},
        ${stroke}px 0 0 ${theme.colors.electricViolet},
        ${stroke}px ${stroke}px 0 ${theme.colors.electricViolet},
        0 ${stroke}px 0 ${theme.colors.electricViolet},
        -${stroke}px ${stroke}px 0 ${theme.colors.electricViolet},
        -${stroke}px 0 0 ${theme.colors.electricViolet};
    `}
`;
