import React, { FC } from 'react';

import {
  Button,
  SmallRectangle,
  MiddleRectangle,
  ShadowBox,
  ColorBox,
} from './styled';

import { Text } from '../../Typography/Text';

type PrimaryButtonProps = {
  text: string;
  withMargin?: string;
  auto?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isBlocked?: boolean;
};

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  text,
  withMargin,
  onClick,
  type,
  isBlocked,
  auto,
}) => (
  <Button
    type={type}
    withMargin={withMargin}
    onClick={onClick}
    disabled={isBlocked}
    auto={auto}
  >
    <SmallRectangle />
    <MiddleRectangle />
    <ShadowBox>
      <ColorBox isBlocked={isBlocked}>
        <Text>{text}</Text>
      </ColorBox>
    </ShadowBox>
  </Button>
);
