import Phaser from 'phaser';

import Game from './scenes/game';
import {
  GAME_CONFIG,
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  MAX_WIDTH,
  MAX_HEIGHT,
  CAMERA_OFFSET,
} from './config';
import { NavBarData } from '../types/navbardata';
import { debounce } from '../utils/common';
import { GameActions } from '../types/gameActions';

export interface PhaserGameArgs {
  onPreloadProgress(progress: number): void;
  onPreloadComplete(): void;
  onUpdateNavbar: (navset: NavBarData) => void;
  onAction: (action: GameActions, data?: Object) => void;
}

export default class PhaserGame {
  private game: Phaser.Game;

  private scene!: Phaser.Scene;

  private fadeOutSounds!: number;

  private fadeInSounds!: number;

  isMuted?: boolean = false;

  pause() {
    if (this.game) {
      this.scene.scene.pause();
      this.pauseSounds(true);
    }
  }

  resume() {
    if (this.game) {
      this.scene.scene.resume();
      if (!this.isMuted) this.resumeSounds();
    }
  }

  getMuted(isMuted: boolean) {
    this.isMuted = isMuted;
  }

  pauseSounds(fade?: boolean) {
    if (fade) {
      clearInterval(this.fadeInSounds);
      this.fadeOutSounds = setInterval(() => {
        if (this.game.sound.volume > 0) {
          this.game.sound.volume -= 0.1;
        } else {
          clearInterval(this.fadeOutSounds);
          this.game.sound.pauseAll();
        }
      }, 50);
    } else this.game.sound.pauseAll();
  }

  resumeSounds() {
    clearInterval(this.fadeOutSounds);
    this.game.sound.volume = 1;
    this.game.sound.resumeAll();
  }

  private resizeGame = debounce(() => {
    this.resize();
  }, 100);

  constructor({
    onPreloadProgress,
    onPreloadComplete,
    onUpdateNavbar,
    onAction,
  }: PhaserGameArgs) {
    this.game = new Phaser.Game({
      ...GAME_CONFIG,
      scene: [Game],
      callbacks: {
        postBoot: this.gameIsReady.bind(this), // A function to run at the end of the boot sequence
      },
    });

    this.game.sound.pauseOnBlur = false;

    Game.onProgress = onPreloadProgress;
    Game.onComplete = onPreloadComplete;
    Game.updateNavbar = onUpdateNavbar;
    Game.sendAction = onAction;

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  gameIsReady() {
    this.scene = this.game.scene.getScene('Game');
    this.resizeGame();
    window.addEventListener('resize', this.resizeGame);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  exit(): void {
    window.removeEventListener('resize', this.resizeGame);
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
    this.game.sound.pauseAll();
    this.game.destroy(false);
    clearInterval(this.fadeInSounds);
    clearInterval(this.fadeOutSounds);
    this.scene.scale.removeAllListeners();
    delete this.scene;
    delete this.game;
  }

  handleVisibilityChange(): void {
    if (this.game && document.hidden) {
      Game.sendAction(GameActions.PAUSE);
    }
  }

  resize() {
    if (this.game && this.game.scale.parent) {
      const w = window.innerWidth;
      const h = window.innerHeight;

      const width = DEFAULT_WIDTH;
      const height = DEFAULT_HEIGHT;
      const maxWidth = MAX_WIDTH;
      const maxHeight = MAX_HEIGHT;

      const scale = Math.min(w / width, h / height);
      const newWidth = Math.min(w / scale, maxWidth);
      const newHeight = Math.min(h / scale, maxHeight);

      // resize the game
      this.game.scale.resize(newWidth, newHeight);
      // scale the width and height of the css
      this.game.canvas.style.width = `${newWidth * scale}px`;
      this.game.canvas.style.height = `${newHeight * scale}px`;

      // update hero position in accordance with camera offset
      if (this.scene)
        this.scene.cameras.main.followOffset.set(
          (this.scene.cameras.main.width / 2) * CAMERA_OFFSET
        );
    }
  }
}
