import styled from 'styled-components';

type ButtonProps = {
  withMargin?: string;
  isBlocked?: boolean;
  auto?: boolean;
};

export const Button = styled.button<ButtonProps>`
  position: relative;
  width: 30vw;
  min-width: 200px;
  height: 2rem;
  border-radius: 0.417rem;
  background-color: ${({ theme }) => theme.colors.violentViolet};
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  user-select: none;

  margin: ${({ withMargin }) => withMargin || '0'};
  ${({ auto }) =>
    auto &&
    `
    flex: 1;
    width:auto;
  `}
`;

export const ShadowBox = styled.div`
  position: absolute;
  width: 100%;
  height: 1.67rem;
  border-radius: 0.417rem;
  background-color: ${({ theme }) => theme.colors.whiteWithOpacity};
  left: 0;
  top: 0.17rem;
  z-index: 1;
`;

export const ColorBox = styled.div<ButtonProps>`
  position: relative;
  width: 100%;
  height: 1.67rem;
  border-radius: 0.417rem;
  background-color: ${({ isBlocked, theme }) =>
    isBlocked ? theme.colors.blackRock : theme.colors.blueGem};
  top: -0.167rem;
  opacity: 1;
  z-index: 2;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;

  &:active {
    background-color: ${({ theme }) => theme.colors.electricViolet};
  }

  &::before {
    position: absolute;
    width: 1.458rem;
    height: 1.67rem;
    opacity: 0.25;
    background-color: ${({ theme }) => theme.colors.white};
    right: -0.75rem;
    top: 0.8rem;
    z-index: -1;
    transform: rotate(45deg);
    content: '';
  }
`;
export const SmallRectangle = styled.div`
  position: absolute;
  width: 1.208rem;
  height: 0.16rem;
  transform: rotate(-38deg);
  opacity: 0.25;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
  top: 0.2rem;
  left: -0.16rem;
  &::before {
    content: ' ';
    height: 0.417rem;
    width: 0.08rem;
    background-color: ${({ theme }) => theme.colors.blueGem};
    position: absolute;
    left: 0.075rem;
    bottom: -0.1rem;
    transform: rotate(38deg);
  }

  &::after {
    content: ' ';
    height: 0.08rem;
    width: 0.417rem;
    background-color: ${({ theme }) => theme.colors.blueGem};
    position: absolute;
    right: 0;
    top: 0.03rem;
    transform: rotate(38deg);
  }
`;

export const MiddleRectangle = styled.div`
  position: absolute;
  width: 1.79rem;
  height: 0.16rem;
  transform: rotate(-38deg);
  opacity: 0.25;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
  top: 0.417rem;
  left: -0.24rem;
  &::before {
    content: ' ';
    height: 0.417rem;
    width: 0.08rem;
    background-color: ${({ theme }) => theme.colors.blueGem};
    position: absolute;
    left: 0.099rem;
    bottom: -0.1rem;
    transform: rotate(38deg);
  }
  &::after {
    content: ' ';
    height: 0.08rem;
    width: 0.417rem;
    background-color: ${({ theme }) => theme.colors.blueGem};
    position: absolute;
    right: -0.04rem;
    top: 0.029rem;
    transform: rotate(38deg);
  }
`;
