import React, { FC, useState, useCallback, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { TutorialText, TutorialFrame, TutorialButtons } from './styled';

import { Container } from '../Loading/styled';
import { IconButton } from '../../components/Buttons/IconButton';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import Video from '../../components/Video';

export const Tutorial: FC = () => {
  const history = useHistory();

  const [refresh, setRefresh] = useState(0);

  const handleClick = useCallback(() => {
    setRefresh((prev) => prev + 1);
  }, []);

  const onButtonClickHandler = useCallback(
    (event: MouseEvent) => {
      const path = '/game';
      history.push(path);
    },
    [history]
  );

  return (
    <Container>
      <TutorialText>SAMOUCZEK</TutorialText>
      <TutorialFrame>
        <Video
          videoName="intro.mp4"
          autoPlay={true}
          muted={false}
          loop={false}
          controls={false}
          refresh={refresh}
        />
      </TutorialFrame>
      <TutorialButtons>
        <IconButton iconName="reload.svg" onClick={handleClick} />
        <PrimaryButton
          text="JESTEM GOTÓW"
          withMargin="0 0 0 1rem"
          auto={true}
          onClick={onButtonClickHandler}
        />
      </TutorialButtons>
    </Container>
  );
};
