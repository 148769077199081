import React, { FC } from 'react';

import { MuteContainer } from './styled';

type MuteButtonProps = {
  muted?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export const MuteButton: FC<MuteButtonProps> = ({ muted, onClick }) => (
  <MuteContainer onClick={onClick}>
    <img
      src={`/icons/${muted ? 'soundOff' : 'soundOn'}.svg`}
      alt="soundChange"
    />
  </MuteContainer>
);
