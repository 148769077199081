import React, { FC } from 'react';

import { HeartImg } from './styled';

type HeartProps = {
  iconName: string;
};

export const Heart: FC<HeartProps> = ({ iconName }) => (
  <HeartImg src={`/icons/${iconName}.svg`} alt="Serce" />
);
