import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from '../../../routes/Loading/styled';
import { PrimaryButton } from '../../Buttons/PrimaryButton';

import { ErrorImg, ErrorText } from './styled';

export const Error500: FC = () => {
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Container>
      <ErrorImg src="./icons/errorIcon.svg" alt="Ikona informująca o błędzie" />
      <ErrorText>UPS... COŚ POSZŁO NIE TAK</ErrorText>
      <PrimaryButton text="POWRÓT" onClick={handleButtonClick} />
    </Container>
  );
};
