import styled from 'styled-components';

import { Text } from '../../Typography/Text';

export const FailText = styled(Text)`
  margin-bottom: 1rem;
`;

export const Score = styled(Text)`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.malibu};
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(4, 4, 50, 0.92);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
`;
