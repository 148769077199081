import {
  HeroI,
  HeroArgs,
  InvisibleConfig,
  VelocityConfig,
} from '../../types/hero';
import { HERO_MESSAGES, MessageType, VIBRATION_CONFIG } from '../config';
import { SoundsTypes } from '../../types/sounds';
import SoundManager from '../soundManager';

export default class Hero
  extends Phaser.Physics.Arcade.Sprite
  implements HeroI {
  lifePoints: number;

  name: string;

  email: string;

  isJumping: boolean;

  isHit: boolean;

  isInvisible: boolean;

  startX: number;

  velocity: VelocityConfig;

  messageTime: number;

  message!: Phaser.Physics.Arcade.Image;

  readonly invisible: InvisibleConfig;

  private isGoing: boolean;

  soundManager: SoundManager;

  constructor({
    x,
    y,
    lifePoints,
    name,
    email,
    texture,
    scene,
    scale,
    velocity,
    invisible,
    messageTime,
  }: HeroArgs & {
    scene: Phaser.Scene;
  }) {
    super(scene, x, y, texture);

    this.lifePoints = lifePoints;
    this.name = name;
    this.email = email;
    this.startX = this.x;
    this.velocity = velocity;
    this.isJumping = false;
    this.isGoing = false;
    this.isInvisible = false;
    this.invisible = invisible;
    this.isHit = false;
    this.messageTime = messageTime;

    if (this.invisible.tickCount % 2 !== 0) this.invisible.tickCount += 1;

    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    this.setOrigin(1, 1);
    this.setCollideWorldBounds(true);
    this.setScale(scale);
    this.getAnimsArray().forEach((val) => this.scene.anims.create(val));
    this.soundManager = SoundManager.getInstance();
  }

  private getAnimsArray(): Array<Phaser.Types.Animations.Animation> {
    return [
      {
        key: 'stand',
        frames: [{ key: this.texture.key, frame: 0 }],
        frameRate: 20,
      },
      {
        key: 'go',
        frames: this.scene.anims.generateFrameNumbers(this.texture.key, {
          start: 1,
          end: 9,
        }),
        frameRate: 10,
        repeat: -1,
      },
      {
        key: 'jump',
        frames: this.scene.anims.generateFrameNumbers(this.texture.key, {
          start: 10,
          end: 13,
        }),
        frameRate: 30,
      },
      {
        key: 'land',
        frames: this.scene.anims.generateFrameNumbers(this.texture.key, {
          start: 14,
          end: 16,
        }),
        frameRate: 30,
      },
      {
        key: 'dead',
        frames: this.scene.anims.generateFrameNumbers(this.texture.key, {
          start: 17,
          end: 19,
        }),
        frameRate: 5,
      },
      {
        key: 'hit',
        frames: this.scene.anims.generateFrameNumbers(this.texture.key, {
          start: 20,
          end: 26,
        }),
        frameRate: 5,
        repeat: 1,
      },
    ];
  }

  showMessage(type: MessageType) {
    if (this.message) this.message.destroy();

    const msgIcon =
      HERO_MESSAGES[type][
        Math.floor(Math.random() * HERO_MESSAGES[type].length)
      ];

    this.message = this.scene.physics.add
      .image(this.x, this.y - this.displayHeight, msgIcon)
      .setOrigin(0, 0.5)
      .setVelocityX(this.body.velocity.x);
    (this.message.body as Phaser.Physics.Arcade.Body).setAllowGravity(false);
    this.message.alpha = 0;

    this.scene.tweens.add({
      targets: this.message,
      alpha: 1,
      duration: 250,
      ease: 'Power2',
    });

    this.scene.time.delayedCall(this.messageTime - 500, () => {
      this.scene.tweens
        .add({
          targets: this.message,
          alpha: 0,
          duration: 250,
          ease: 'Power2',
        })
        .on('complete', () => {
          this.message.destroy();
        });
    });
  }

  follow(camera: Phaser.Cameras.Scene2D.Camera): void {
    camera.startFollow(this);
  }

  reset(): void {
    this.x = this.startX;
  }

  update(): void {
    if (this.message && this.message.active)
      this.message.y = this.y - this.displayHeight;

    if (this.isJumping) {
      if ((this.body as Phaser.Physics.Arcade.Body).onFloor()) {
        this.isJumping = false;
        if (this.isAlive()) {
          this.anims.play(this.isHit ? 'hit' : 'land');
          this.anims.nextAnim = this.isGoing ? 'go' : 'stand';
        } else this.anims.play('dead');
      } else {
        this.isJumping = true;
      }
    }
  }

  go(shouldGo: boolean, speed?: number): void {
    if (shouldGo) {
      this.anims.play('go', true);
      this.isGoing = true;
      this.setVelocityX(speed || this.velocity.walk);
    } else {
      this.anims.play('stand', true);
      this.isGoing = false;
      this.setAccelerationX(0).setVelocityX(0);
      if (this.message && this.message.active) {
        this.message.setAccelerationX(0).setVelocityX(0);
      }
    }
  }

  jump(velocity?: number, force?: boolean): void {
    if (
      (!this.isJumping &&
        this.isAlive() &&
        (this.body as Phaser.Physics.Arcade.Body).onFloor()) ||
      force
    ) {
      this.anims.play('jump');
      this.isJumping = true;
      this.setVelocityY(velocity || this.velocity.jump);
      this.soundManager.play(SoundsTypes.JUMP);
    }
  }

  isAlive(): boolean {
    return this.lifePoints > 0;
  }

  loseLife(): number {
    const allowVibrate = typeof window.navigator?.vibrate === 'function';

    this.showMessage(MessageType.HIT);
    this.soundManager.play(SoundsTypes.OOF);

    this.scene.cameras.main.shake(250, 0.03);
    this.isHit = true;
    this.lifePoints -= 1;
    this.isInvisible = true;

    if (this.isAlive()) {
      this.anims.play('hit');
      this.anims.nextAnim = this.isGoing ? 'go' : 'stand';
      this.scene.time.delayedCall(this.invisible.time, () => {
        this.isInvisible = false;
        this.isHit = false;
      });
      if (allowVibrate) {
        window.navigator.vibrate(VIBRATION_CONFIG.hit);
      }
    } else {
      this.scene.sound.pauseAll();
      this.soundManager.play(SoundsTypes.GAME_OVER);
      this.killYourself();

      if (allowVibrate) {
        window.navigator.vibrate(VIBRATION_CONFIG.dead);
      }
    }

    return this.lifePoints;
  }

  killYourself(): void {
    this.isHit = true;
    this.go(false);
    this.anims.play('dead');
  }
}
