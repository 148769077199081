export const introData = [
  {
    header: 'HOP & TRACH! ZAGRAJ W CENTRUM KOMIKSU I NARRACJI INTERAKTYWNEJ',
    textPt1: 'W tej grze zwiedzisz powstające w Łodzi Centrum Komiksu i Narracji Interaktywnej.',
    textPt2: 'To pierwsza tego typu instytucja w Polsce! Dowiesz się w niej, jak powstają gry wideo i komiksy, poznasz znakomitych artystów oraz sam będziesz mógł poczuć się jak twórca.',
    img: ''
  },
  {
    header: '',
    textPt1: 'Zagraj z bohaterami kultowego komiksu „Tytus, Romek i A\'Tomek” i odkryj trzy piętra Centrum Komiksu i Narracji Interaktywnej – pełnego atrakcji ze świata gier i historii obrazkowych.',
    textPt2: '',
    img: ''
  },
  {
    header: '',
    textPt1: 'W CKNI znajdzie się Strefa Retro. Poznasz tam początki kultury gier w Polsce: giełdy, salony gier i codzienne życie gracza w latach 80. i 90. XX wieku.',
    textPt2: '',
    img: './img/intro3.png'
  },
  {
    header: '',
    textPt1: 'Tylko w CKNI znajdzie się interaktywna gra o tworzeniu gier komputerowych w uniwersum Wiedźmina oraz doświadczenia VR i AR. Będzie tam też stała wystawa komiksowa, strefa chillout i sale warsztatowe.',
    textPt2: '',
    img: './img/intro4.png'
  },
  {
    header: '',
    textPt1: 'Wiesz już, co znajdzie się w Centrum Komiksu i Narracji Interaktywnej, powstającym w kompleksie EC1 Łódź. Śledź newsy – nie może zabraknąć Cię na otwarciu!',
    textPt2: '',
    img: ''
  }
];