import React from 'react';

import { VideoContainer } from './styled';

type VideoProps = {
  videoName: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  refresh?: number;
};

const Video = (props: VideoProps) => {
  const videoRef = React.useRef<HTMLMediaElement>(null);

  React.useEffect(() => {
    videoRef.current?.load();
  }, [props.refresh]);

  return (
    <VideoContainer
      muted={props.muted}
      autoPlay={props.autoPlay}
      loop={props.loop}
      controls={props.controls}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={videoRef as any}
    >
      <track kind="captions" />
      <source src={`/video/${props.videoName}`} type="video/mp4" />
    </VideoContainer>
  );
};

Video.displayName = 'Video';

export default Video;
