import styled from 'styled-components';

export const StyledLinkButton = styled.a`
  height: 0.8rem;
  font-family: 'JoystixMonospace';
  font-size: 0.66rem;
  text-align: center;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.purpleHeart };
  cursor: pointer;
  display: inline-block;
  background-color: Transparent;
  outline: none;
  box-shadow: none;
  border: none;
  user-select: none;
  margin: 0 8px;

  @media (max-width: 470px) {
    font-size: 0.4rem;
  }
`;
