import React, { FC } from 'react';

import {
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxContainer,
  Icon,
} from './styled';

type CheckboxProps = {
  checked?: boolean;
  onChange?: () => void;
};

export const Checkbox: FC<CheckboxProps> = ({ checked, onChange }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledCheckbox checked={checked} onChange={onChange}>
      <Icon viewBox="0 0 24 24">
        <rect x="6" y="6" width="12" height="12" rx="2" ry="2" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);
