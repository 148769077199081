import { EdibleItemI, EdibleItemArgs } from '../../types/edibleItem';

export default class EdibleItem
  extends Phaser.Physics.Arcade.Image
  implements EdibleItemI {
  private bgTexture: string;

  bgAsset!: Phaser.Physics.Arcade.Sprite;

  score: number;

  constructor({
    x,
    y,
    score,
    bgTexture,
    texture,
    scene,
  }: EdibleItemArgs & {
    scene: Phaser.Scene;
  }) {
    super(scene, x, y, texture);
    this.score = score;
    this.bgTexture = bgTexture;

    this.bgAsset = this.scene.physics.add.sprite(
      this.x,
      this.y,
      this.bgTexture
    );

    this.createAnims();
    this.bgAsset.anims.play('shine');

    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    (this.body as Phaser.Physics.Arcade.Body).setAllowGravity(false);
    (this.bgAsset.body as Phaser.Physics.Arcade.Body).setAllowGravity(false);
  }

  private createAnims() {
    this.scene.anims.create({
      key: 'shine',
      frames: this.scene.anims.generateFrameNumbers(this.bgTexture, {
        start: 0,
        end: 2,
      }),
      frameRate: 10,
      repeat: -1,
    });
  }
}
