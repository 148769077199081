import React, { FC } from 'react';

import { StyledLinkButton } from './styled';

type LinkButtonProps = {
  text: string;
  href: string;
  title: string;
};

export const LinkButton: FC<LinkButtonProps> = ({
  text,
  ...restProps
}) => (
  <StyledLinkButton {...restProps} target="_blank" rel="noopener">
    {text}
  </StyledLinkButton>
);
