import styled from 'styled-components';

import { Text } from '../../../Typography/Text';

export const ErrorImg = styled.img`
  margin-bottom: 1rem;
`;

export const ErrorText = styled(Text)`
  margin-bottom: 1rem;
`;
