import React, { FC } from 'react';

import { Heart } from './Heart';

import { Container } from './styled';

type HeartsProps = {
  lives: number;
};

export const Hearts: FC<HeartsProps> = ({ lives = 3 }) => (
  <Container>
    {Array(lives)
      .fill(0)
      .map((_, i) => (
        <Heart iconName="heart" key={i.toString()} />
      ))}
  </Container>
);
