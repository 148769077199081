import React, { FC, useCallback } from 'react';

import { PrimaryButton } from '../Buttons/PrimaryButton';

import {
  TitleText,
  TextFrame,
  TermsText,
  TextWrapper,
  TermsContainer,
} from './styled';

type TermConditionsProps = {
  isShown: () => void;
};

export const TermsConditions: FC<TermConditionsProps> = ({ isShown }) => {
  const callbackHandleOk = useCallback(() => {
    isShown();
  }, [isShown]);

  return (
    <TermsContainer>
      <TitleText>REGULAMIN</TitleText>
      <TextFrame>
        <TextWrapper>
          <TermsText>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta
            nemo nam itaque expedita beatae quasi quis quos sit ipsa, quo rem
            nulla odio repellat, voluptates dolore velit error culpa deleniti
            fugit. Odio minima quisquam eaque dolore, enim dolorem vel tenetur
            voluptatibus nisi sint corporis dolor non molestiae placeat
            recusandae numquam ducimus error adipisci a tempore odit dicta qui
            magnam iusto. Sunt cumque pariatur sed adipisci labore molestiae
            quia tempora quam, quas dicta cum iusto veniam vitae quod
            distinctio, dolores dolor sint nihil minus nulla aliquid nesciunt!
            Sint accusantium quidem ipsam ut quam illum reiciendis labore veniam
            modi molestias sunt, et quod optio rem deleniti nostrum facilis
            saepe laboriosam? Minima aperiam illo eaque dolore necessitatibus
            debitis distinctio impedit eveniet accusamus, inventore odio harum
            tempore eius? Eius doloribus explicabo ex nemo quam fugit modi nihil
            sed, similique natus eaque provident maiores ipsam est saepe culpa
            molestias dolorum, accusantium facere id. Aliquid nemo molestiae
            dolor, ipsam ipsa velit inventore consequatur vitae et at
            exercitationem non. Quos perspiciatis nemo provident alias sed,
            neque asperiores numquam voluptatum odio esse eaque at cumque fuga
            labore eveniet sit? Distinctio fuga laborum molestiae ex excepturi
            iste exercitationem ea. Quos, assumenda neque iste fugiat nesciunt
            magni sit omnis rem!
          </TermsText>
        </TextWrapper>
      </TextFrame>
      <PrimaryButton text="OK" type="button" onClick={callbackHandleOk} />
    </TermsContainer>
  );
};
