import React, { FC } from 'react';

import { StyledRow, Icon, Score, Name } from './styled';

type RowProps = {
  id?: number;
  score: number;
  name?: string;
  iconName?: string;
  primary?: boolean;
};

export const Row: FC<RowProps> = ({ score, name, id, iconName, primary }) => (
  <StyledRow primary={primary}>
    <Icon>
      {id}. <img src={`/icons/${iconName}.svg`} alt={iconName} />
    </Icon>
    <Score>{score}</Score>
    <Name>{name}</Name>
  </StyledRow>
);
