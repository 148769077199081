import styled from 'styled-components';

import { ErrorContainer, ErrorTitle, ErrorImg } from '../../Error/styled';

export const OrientationContainer = styled(ErrorContainer)`
  z-index: 2;
`;

export const OrientationErrorTitle = styled(ErrorTitle)`
  margin-top: 0;
`;

export const OrientationErrorImg = styled(ErrorImg)`
  margin-bottom: 0;
  margin-top: 2rem;
`;
