import { useState, useEffect } from 'react';

import { debounce } from './common';

const checkPortrait = () => window.innerHeight > window.innerWidth;

const useIsPortrait = (callback: () => void): boolean => {
  const [isPortrait, setIsPortrait] = useState(checkPortrait());

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(checkPortrait());
    };
    const debouncedResize = debounce(handleResize, 100);
    
    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, []);

  useEffect(() => {
    callback();
  }, [isPortrait, callback]);

  return isPortrait;
};

export { useIsPortrait };
