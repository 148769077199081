import axios, { AxiosInstance } from 'axios';
import { Score } from '../types/score';
import { AXIOS_BASE_CONF } from './config';

const instance: AxiosInstance = axios.create(AXIOS_BASE_CONF);

export const addScore = async (
  url: string,
  data: { name: string; score: number; email: string; base: string }
): Promise<boolean> => {
  const response = await instance.post(url, data);
  if (response.data.success) {
    localStorage.setItem('userDocID', response.data.data);
    return true;
  }
  throw new Error(response.data.error);
};

export const getLeaderboard = async (
  url: string,
  id: string | null
): Promise<Array<Score>> => {
  const response = await instance.get(url, { params: { id } });
  if (response.data.success) {
    return response.data.data;
  }
  throw new Error(response.data.error);
};
