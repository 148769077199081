import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Container } from '../Loading/styled';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';

import { MarginText } from './styled';

export const NotFound: FC = () => (
  <Container>
    <MarginText>TAKA STRONA NIE ISTNIEJE</MarginText>
    <Link to={{ pathname: '/' }}>
      <PrimaryButton text="POWRÓT" />
    </Link>
  </Container>
);
