import React, { FC, useState } from 'react';

import GameComponent from '../Game';

export const GameWrapper: FC = () => {
  const [attempt, setAttempt] = useState(0);
  const handleAttempt = () => {
    setAttempt(attempt + 1);
  };

  return (
    <GameComponent key={`game-${attempt}`} handleAttempt={handleAttempt} />
  );
};
