import styled from 'styled-components';

import { Text } from '../../../components/Typography/Text';

export const Container = styled.div`
  padding: 96px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blackRock};
`;

export const ScrollDiv = styled.div`
  max-height: 100%;
  overflow: auto;
`;

export const HeaderText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 24px;
`;

export const SubtitleText = styled(Text)`
  font-size: 0.75rem;
  margin-bottom: 48px;
`;

export const BodyText = styled(Text)`
  font-size: 0.50rem;
  color: ${({ theme }) => theme.colors.white};
  max-width: 900px;
  user-select: auto;

  @media (min-width: 470px) {
    font-size: 0.66rem;
  }

  h1 {
    margin-block: 1.25em;
  }

  h2 {
    margin-block: 1em;
  }
`;
