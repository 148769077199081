import React, { FC, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import Game from '../../game/scenes/game';

import { OrientationError } from './OrientationError';
import { Error } from './Error';

import { checkBrowser } from '../../utils/ua';
import { useIsPortrait } from '../../utils/useIsPortrait';
import { GameActions } from '../../types/gameActions';
import { NO_ERROR_PATHS } from '../../constants';

type ErrorPageProps = {
  children: ReactNode;
};

export const ErrorPages: FC<ErrorPageProps> = ({ children }) => {
  const location = useLocation();

  const updateGame = () => {
    if (typeof Game.sendAction === 'function' && isPortrait)
      Game.sendAction(GameActions.PAUSE);
  };

  const isPortrait = useIsPortrait(updateGame);
  const isMobile = useMediaQuery({ query: '(max-device-width: 1000px)' });
  const isBrowserSupported = checkBrowser();

  return NO_ERROR_PATHS.includes(location.pathname) ? (
    <>{children}</>
  ) : (
    <>
      {isPortrait && <OrientationError />}
      {(!isMobile || !isBrowserSupported) && (
        <Error error={{ isMobile, isBrowserSupported }} />
      )}
      {isMobile && isBrowserSupported && <>{children}</>}
    </>
  );
};
