import styled from 'styled-components';

import { Text } from '../../Typography/Text';

export const Header = styled.div`
  user-select: none;
  position: absolute;
  transform: translateY(-20%);
  height: 1.625rem;
  left: 50%;
  transform: translateX(-55%);
  display: flex;
  align-items: center;
  width: 80vw;
  background-color: ${({ theme }) => theme.colors.purpleHeart};
  justify-content: space-between;
  border-bottom-left-radius: 0.417rem;
  border-bottom-right-radius: 0.417rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: solid 2px ${({ theme }) => theme.colors.purple};
`;

export const NavText = styled(Text)`
  margin-right: 10px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const ScoreText = styled(NavText)`
  color: ${({ theme }) => theme.colors.whiteWithOpacity};
`;

export const ScoreDiv = styled.div`
  display: flex;
  align-items: center;
`;
