import styled from 'styled-components';

import { Text } from '../../Typography/Text';
import { Container } from '../../../routes/Loading/styled';

export const TitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.electricViolet};
  margin-bottom: 0.75rem;
`;

export const TermsContainer = styled(Container)`
  position: absolute;
  z-index: 4;
`;

export const TermsText = styled(Text)`
  width: 60vw;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.5rem;
  padding: 0.3rem 0.6rem;
  display: inline-block;
  text-align: left;
  touch-action: pan-y;
`;

export const TextWrapper = styled.div`
  width: 65vw;
  max-height: 11rem;
  height: 45vh;
  overflow-y: scroll;
  margin-top: 0.25rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.6rem;
    height: 1.16rem;
    background-color: ${({ theme }) => theme.colors.electricViolet};
    border-radius: 0.29rem;
  }

  &::-webkit-scrollbar-track {
    overflow: auto;
    background-color: white;
    border: 0.25rem solid ${({ theme }) => theme.colors.blackRock};
  }
`;

export const TextFrame = styled.div`
  width: 60vw;
  max-height: 11.25rem;
  height: 50vh;
  border-radius: 0.67rem;
  border: solid 2px ${({ theme }) => theme.colors.electricViolet};
  margin-bottom: 1rem;
`;
