import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import { updateVh } from '../../utils/common';

import {
  LandingContainer,
  Img,
  Overlap,
  View,
  CookiesText,
  CookiesTextMalibu,
} from './styled';

export const Landing: FC = () => {
  const history = useHistory();

  React.useEffect(() => updateVh, []);

  const goToGame = React.useCallback(() => {
    history.push('/intro');
  }, [history]);

  return (
    <LandingContainer>
      <View>
        <Img
          alt="Landing"
          src="assets/img/landing/tytus-romek-i-atomek.png"
          srcSet={`assets/img/landing/tytus-romek-i-atomek@2x.png 2x,
      assets/img/landing/tytus-romek-i-atomek@3x.png 3x`}
        />
        <Overlap>
          <PrimaryButton text="ROZPOCZNIJ" onClick={goToGame} />

          <CookiesText>
            <CookiesTextMalibu>
              NA TEJ STRONIE UŻYWAMY CIASTECZEK.
            </CookiesTextMalibu>
            &nbsp;GRAJĄC W TĘ GRĘ, ZGADZASZ SIĘ NA PRZECHOWYWANIE I
            PRZETWARZANIE CIASTECZEK NA TWOIM URZĄDZENIU :)
          </CookiesText>
        </Overlap>
      </View>
    </LandingContainer>
  );
};
