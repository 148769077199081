import { SoundsTypes } from '../types/sounds';

export const MANIFEST_IMAGES = [
  {
    path: '/icons/pen-1.svg',
    name: 'pen_1',
  },
  {
    path: '/icons/pen-2.svg',
    name: 'pen_2',
  },
  {
    path: '/icons/pen-3.svg',
    name: 'pen_3',
  },
  {
    path: '/icons/compass.svg',
    name: 'compass',
  },
  {
    path: '/icons/setsquare.svg',
    name: 'setsquare',
  },
  {
    path: '/icons/gameboy_1.svg',
    name: 'gameboy_1',
  },
  {
    path: '/icons/gameboy_2.svg',
    name: 'gameboy_2',
  },
  {
    path: '/icons/ruler.svg',
    name: 'ruler',
  },
  {
    path: 'assets/img/levelBg/lvl1Final.jpg',
    name: 'level1_bg',
  },
  {
    path: 'assets/img/levelBg/lvl2Final.jpg',
    name: 'level2_bg',
  },
  {
    path: 'assets/img/levelBg/lvl3Final.jpg',
    name: 'level3_bg',
  },
  {
    path: '/icons/box.svg',
    name: 'obstacle_box',
  },
  {
    path: '/icons/liquid.svg',
    name: 'obstacle_liquid',
  },
  {
    path: '/icons/banana.svg',
    name: 'obstacle_banana',
  },
  {
    path: '/icons/bin.svg',
    name: 'obstacle_bin',
  },
  {
    path: '/icons/bin-with-trash.svg',
    name: 'obstacle_bin_trash',
  },
  {
    path: '/icons/100-p.svg',
    name: '100points',
  },
  {
    path: '/icons/200-p.svg',
    name: '200points',
  },
  {
    path: '/icons/300-p.svg',
    name: '300points',
  },
  {
    path: '/img/enemyDead.png',
    name: 'deadEnemy',
  },
  {
    path: '/icons/hero_bubbles/ouch.svg',
    name: 'ouch',
  },
  {
    path: '/icons/hero_bubbles/face.svg',
    name: 'face',
  },
  {
    path: '/icons/hero_bubbles/oh_no.svg',
    name: 'oh_no',
  },
  {
    path: '/icons/hero_bubbles/ojoj.svg',
    name: 'ojoj',
  },
  {
    path: '/icons/hero_bubbles/random.svg',
    name: 'random',
  },
  {
    path: '/icons/hero_bubbles/made_it.svg',
    name: 'made_it',
  },
  {
    path: '/icons/hero_bubbles/uff.svg',
    name: 'uff',
  },
  {
    path: '/icons/hero_bubbles/oops.svg',
    name: 'oops',
  },
  {
    path: '/icons/hero_bubbles/wrrr.svg',
    name: 'wrrr',
  },

  {
    path: '/img/robot_1_dead.png',
    name: 'robot_1_dead',
  },
  {
    path: '/img/robot_2_dead.png',
    name: 'robot_2_dead',
  },
  {
    path: '/img/robot_3_dead.png',
    name: 'robot_3_dead',
  },

  {
    path: '/icons/book_1.png',
    name: 'book_1',
  },
  {
    path: '/icons/book_2.png',
    name: 'book_2',
  },
  {
    path: '/icons/book_3.png',
    name: 'book_3',
  },
  {
    path: '/icons/book_4.png',
    name: 'book_4',
  },
];

export const MANIFEST_SPRITES = [
  {
    path: '/img/main-hero.png',
    name: 'hero',
    options: {
      frameWidth: 128,
      frameHeight: 192,
    },
  },
  {
    path: '/img/blink.png',
    name: 'star',
    options: {
      frameWidth: 96,
      frameHeight: 96,
    },
  },
  {
    path: '/img/robot_1.png',
    name: 'robot_1',
    options: {
      frameWidth: 150,
      frameHeight: 180,
    },
  },
  {
    path: '/img/robot_2.png',
    name: 'robot_2',
    options: {
      frameWidth: 100,
      frameHeight: 150,
    },
  },
  {
    path: '/img/robot_3.png',
    name: 'robot_3',
    options: {
      frameWidth: 150,
      frameHeight: 194,
    },
  },
];

export const MANIFEST_SOUNDS = [
  {
    path: '/sounds/background_music.mp3',
    name: SoundsTypes.BG_MUSIC,
    config: {
      volume: 0.5,
      loop: true,
    },
  },
  {
    path: '/sounds/game_over.mp3',
    name: SoundsTypes.GAME_OVER,
    config: { volume: 1 },
  },
  {
    path: '/sounds/jump.mp3',
    name: SoundsTypes.JUMP,
    config: { volume: 0.4 },
  },
  {
    path: '/sounds/oof.mp3',
    name: SoundsTypes.OOF,
    config: { volume: 1 },
  },
  {
    path: '/sounds/pickup.mp3',
    name: SoundsTypes.PICKUP,
    config: { volume: 0.15 },
  },
];
