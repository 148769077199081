import styled from 'styled-components';

export const Button = styled.button`
  position: relative;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  width: 2.7rem;
  height: 2rem;
  border-radius: 0.416rem;
  background-color: ${({ theme }) => theme.colors.atoll};
`;

export const Logo = styled.div`
  position: absolute;
  text-align: center;
  top: 0.5rem;
  left: 1rem;
`;

export const ShadowBox = styled.div`
  position: absolute;
  width: 2.7rem;
  height: 1.67rem;
  border-radius: 0.416rem;
  background-color: ${({ theme }) => theme.colors.whiteWithOpacity};
  z-index: 1;
  left: 0;
  top: 0.167rem;
`;

export const ColorBoxReplay = styled.div`
  position: absolute;
  width: 2.7rem;
  height: 1.67rem;
  border-radius: 0.416rem;
  background-color: ${({ theme }) => theme.colors.matisse};
  top: -0.167rem;
  z-index: 1;
  overflow: hidden;
  &::before {
    position: absolute;
    width: 1.45rem;
    height: 1.67rem;
    opacity: 0.25;
    background-color: ${({ theme }) => theme.colors.white};
    right: -0.75rem;
    top: 0.75rem;
    z-index: -1;
    transform: rotate(45deg);
    content: '';
  }
`;

export const SmallRectangleReplay = styled.div`
  position: absolute;
  width: 1.2rem;
  height: 0.167rem;
  transform: rotate(-38deg);
  opacity: 0.25;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 2;
  top: 0.167rem;
  left: -0.2rem;
  &::before {
    content: ' ';
    height: 0.417rem;
    width: 0.08rem;
    background-color: ${({ theme }) => theme.colors.matisse};
    position: absolute;
    left: 0.075rem;
    bottom: -0.1rem;
    transform: rotate(38deg);
  }
  &::after {
    content: ' ';
    height: 0.08rem;
    width: 0.417rem;
    background-color: ${({ theme }) => theme.colors.matisse};
    position: absolute;
    right: 0;
    top: 0.04rem;
    transform: rotate(38deg);
  }
`;

export const MiddleRectangleReplay = styled.div`
  position: absolute;
  width: 1.79rem;
  height: 0.167rem;
  transform: rotate(-38deg);
  opacity: 0.25;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 2;
  top: 0.375rem;
  left: -0.33rem;

  &::before {
    content: ' ';
    height: 0.54rem;
    width: 0.08rem;
    background-color: ${({ theme }) => theme.colors.matisse};
    position: absolute;
    left: 0.09rem;
    bottom: -0.25rem;
    transform: rotate(38deg);
  }

  &::after {
    content: ' ';
    height: 0.08rem;
    width: 0.417rem;
    background-color: ${({ theme }) => theme.colors.matisse};
    position: absolute;
    transform: rotate(38deg);
    right: 0.02rem;
    top: 0;
  }
`;
